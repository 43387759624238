import React from 'react'
import TopFoot from './TopFoot'
import BotFoot from './BotFoot'

const Footer = () => {
  return (
    <div className='w-full h-full bg-footer flex flex-col'>
      <TopFoot/>
      <BotFoot/>
    </div>
  )
}

export default Footer