import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IoMdAddCircleOutline } from 'react-icons/io';
function ExpandingTile({ title, content }) {
    return (
        <Accordion className='border-t-2' elevation="0">
            <AccordionSummary
                expandIcon={<IoMdAddCircleOutline size="25" />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography className='text-primary_color' style={{ fontWeight: 600 }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography className='text-left'>
                    {content}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default ExpandingTile
