import React, { useState } from "react";
import Film from "../components/corporate/Film";
import Brochure from "../components/corporate/Brochure";
import VideoModal from "../components/corporate/VideoModal";
import TopBanner from "../components/common/TopBanner";
import image from "../assets/corporate/banner.png";

const Corporate = () => {
  const [tab, setTab] = useState("film");
  const [modal, setModal] = useState(false);
  const [currVid, setCurrVid] = useState(null);

  const films = [
    {
      id: 1,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 2,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 3,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 4,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 1,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 2,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 3,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 4,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 1,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 2,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 3,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 4,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
  ];

  const brochures = [
    {
      id: 1,
      link: "sample",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
    {
      id: 2,
      link: "test",
      date: "24 January 2024",
      title: "Emergency Care Checklists",
    },
  ];

  return (
    <div className="w-full h-auto flex flex-col items-center gap-5 pb-10 ">
     
      <TopBanner
        title={`Corporate ${tab === "film" ? "Film" : "Brochure"}`}
        image={image}
      />
      <div className="w-full sm:w-[86vw] flex flex-col p-5 gap-3 mt-10">
        <div className="w-full sm:w-1/2 flex ">
          <div
            className={`w-1/2 border-b-[4px]  ${
              tab === "film" ? "border-primary_color" : "border-[#EAECF1]"
            } `}
          >
            <span
              onClick={() => setTab("film")}
              className={`font-semibold ${
                tab === "film" ? "text-primary_color" : "text-[#7688A8]"
              } cursor-pointer`}
            >
              Corporate Film
            </span>
          </div>

          <div
            className={`w-1/2 border-b-[4px] ${
              tab === "brochure" ? "border-primary_color" : "border-[#EAECF1]"
            } `}
          >
            <span
              onClick={() => setTab("brochure")}
              className={`font-semibold ${
                tab === "brochure" ? "text-primary_color" : "text-[#7688A8]"
              } cursor-pointer`}
            >
              Brochures
            </span>
          </div>
        </div>

        <div className="w-full h-auto mt-5 flex flex-wrap gap-10 ">
          {tab === "film"
            ? films?.map((film, i) => (
                <Film
                  key={i}
                  data={film}
                  setCurrVid={setCurrVid}
                  setModal={setModal}
                />
              ))
            : brochures?.map((brochure, i) => (
                <Brochure key={i} data={brochure} />
              ))}
        </div>
      </div>
      <button className="self-center w-fit border-[3px] border-primary_color rounded-lg text-primary_color font-semibold text-lg py-4 px-8">
        See More
      </button>

      {modal && (
        <div className="fixed top-0 left-0 w-[100vw] sm:w-full h-full sm:h-screen overflow-auto sm:bg-black/50 flex items-center justify-center z-50">
          <VideoModal data={currVid} setModal={setModal} />
        </div>
      )}
    </div>
  );
};

export default Corporate;
