import Asokan from "../assets/leaders/1.png"
import Subramonian from "../assets/leaders/2.png"
import Advaith from "../assets/leaders/3.png"
import Aathithya from "../assets/leaders/4.png"
import Athulya from "../assets/leaders/5.png"


export const data = [
    {
        title: 'Dr. S P Asokan',
        content: 'Chairman & Managing Director',
        color: '#4ED6F9',
        image: Asokan
    },
    {
        title: 'S P Subramonian',
        content: 'Joint Chairman & Managing Director',
        color: '#4ED6F9',
        image: Subramonian
    },
    {
        title: 'Dr. Aathithya',
        content: 'Executive Director',
        color: '#4EF0E6A6',
        image: Aathithya
    },
    {
        title: 'Dr. Athulya A Bhagya',
        content: 'Executive Director',
        color: '#4EF0E6A6',
        image: Athulya
    },
    {
        title: 'Advaith A Bala',
        content: 'Executive Director',
        color: '#4ED6F9',
        image: Advaith
    },]