import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { testimonials } from "../../../data/testimonials";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <div id='testimonials' className="max-w-common mx-auto p-3 md:py-20 mt-10 relative flex flex-col items-center">
      {/* Header */}
      <div className="flex w-full items-center justify-center mb-10">
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-2xl md:text-headings font-sans capitalize">
            {t("testimonials.title")}
          </h1>

          <h6 className="mt-2 font-light">{t("testimonials.subTitle")}</h6>
        </div>
      </div>

      <div className="w-1/2 flex justify-center items-center ">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
          stopOnHover={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
        >
          {testimonials.map((item, i) => (
            <div
              key={item.id}
              className="h-auto flex flex-col items-center justify-center gap-4 pb-20"
            >
              <div className="border border-primary_color/50 p-2 rounded-lg">
                <img
                  src={item.pic}
                  alt="patient"
                  className="object-contain w-[200px] h-[150px] "
                />
              </div>

              <h4 className="font-bold text-xl font-sans capitalize text-primary_color">
                {item.name}
              </h4>

              <p className="max-md:w-2/5 max-md:text-wrap italic font-[300]">“ {item.words} ”</p>
            </div>
          ))}

          {/* <div className="flex flex-col items-center justify-center gap-4">
            <img
              src={require("../../../assets/testimonial/testimonial.png")}
              alt="patient"
              className="object-contain w-[200px] h-[200px]"
            />

            <h4 className="font-bold text-xl font-sans capitalize">
              Reo George
            </h4>

            <p className='w-50'>
              “ Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries. ”
            </p>

          </div> */}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
