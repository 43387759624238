import React, { useEffect, useState } from "react";
// import opTheatre from '../../assets/JoinUs/op_theatre.png'
import { motion } from "framer-motion";

function SpecialityCard({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState("0");
  const [paddingvalue, setPadding] = useState("0");

  useEffect(() => {
    if (isOpen) {
      setHeight("fit-content");
      setPadding("1.5rem");
    } else {
      setHeight("0px");
      setPadding("0px");
    }
  }, [isOpen]);

  return (
    <motion.div
      style={{
        borderBottom: isOpen
          ? "0px"
          : "7px solid rgb(133 32 101 / var(--tw-border-opacity))",
      }}
      className="rounded-3xl  border-primary_color cursor-pointer w-full "
      onClick={() => setIsOpen(!isOpen)}
      id={data?.id}
    >
      <div className="relative w-full  h-[200px] md:h-[300px] bg-red-300 rounded-2xl overflow-clip">
        <div className="flex items-center absolute w-full h-fit top-0 bottom-0 my-auto gap-x-5 max-md:gap-x-2 z-10">
          <div className="h-[25px] max-md:h-[15px]  w-[15%] bg-white  rounded-r-full"></div>
          <div className="h-[65px]  w-[65px] max-md:h-[45px] max-md:w-[45px] max-md:p-1 bg-primary_color rounded-full flex items-center justify-center ">
            {/* <img className='w-[40px] h-[40px] object-cover' src={require(`../../assets/specialities/${data?.icon}`)} alt="" /> */}
          </div>
          <h1 className="text-3xl text-start uppercase font-semibold max-md:text-xl text-white ">
            {data?.Heading}
          </h1>
        </div>
        <div className="w-full h-full bg-gradient-to-t from-black/60 absolute top-0 right-0 "></div>
        <img
          className="h-[200px] md:h-[400px]  w-full object-cover"
          src={data?.image}
          alt={`${data?.image}`}
        />
      </div>

      <motion.div
        transition={{ duration: 0.3 }}
        initial={{
          height: "0px",
          paddingTop: "0px",
          paddingBottom: "0",
          opacity: "0",
          borderBottom: "0px",
        }}
        animate={{
          height: height,
          paddingTop: paddingvalue,
          paddingBottom: paddingvalue,
          opacity: isOpen ? "1" : "0",
          borderBottom: isOpen
            ? "7px solid rgb(133 32 101 / var(--tw-border-opacity))"
            : "0px",
        }}
        className=" flex flex-col items-center justify-center  rounded-2xl "
      >
        <motion.div className="w-[70%] max-md:w-[85%] text-left">
          <p className="text-xl max-md:text-lg max-md:leading-6">
            {data?.content &&
              data?.content?.map((item, i) => (
                <div key={item?.id} className="my-3">
                  <h1 className="font-semibold text-primary_color my-3">{item?.subHeading}</h1>
                  <p className="text-sm">{item?.content}</p>
                </div>
              ))}
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default SpecialityCard;
