import React, { useEffect, useRef, useState } from 'react'
import { BsPersonFill } from 'react-icons/bs'
import {SyncLoader} from 'react-spinners'

const Message = ({ msg }) => {
    const ref = useRef()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
        if(msg.name === "bot"){
            setLoading(true)

            setTimeout(()=>{
                setLoading(false)
            },[1500])
        }
    }, [msg])

    return (
        <div ref={ref} className={`w-full h-auto flex gap-2 items-center mb-6 ${(msg.name === 'bot') ? 'flex-row self-start' : 'flex-row-reverse self-end'}`} >
            <div className='flex-none bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center self-end'>
                {
                    msg.name === 'bot'
                        ?
                        <img
                            className='flex-none w-[40px] h-[40px]'
                            src={require("../../../assets/anime/docbot.png")} />
                        :
                        <BsPersonFill size={30} color='#852065' />

                }
            </div>
            <div
                className={`max-w-64 w-fit h-[40px] p-3 px-5 overflow-x-scroll rounded-2xl ${msg.name === 'bot' ? 'bg-white rounded-bl-none' : 'bg-primary_color text-white rounded-br-none'}  `}
            >
                <p className='w-fit text-left text-ellipsis text-sm'>
                    {
                        loading
                        ?
                        <SyncLoader loading={loading} size={10} color='#852065' />
                        :
                        msg.text
                    }
                </p>

            </div>
        </div>
    )
}

export default Message