import React from 'react'

const Awareness = () => {
    return (
        <div data-aos='fade-up' className='w-full h-auto flex flex-col p-10 items-start border-b-4 border-primary_color rounded-lg shadow-lg'>
            <h2 className='text-primary_color font-bold'>Introduction:</h2>
            <p className='text-left text-[#737373]'>
                In today's interconnected world, public health awareness stands as a cornerstone of community well-being.
                From preventing the spread of infectious diseases to promoting mental wellness, raising awareness about public
                health issues empowers individuals to make informed choices for themselves and their communities. This
                article delves into the significance of public health awareness and explores how it can positively impact
                society.
            </p>

            <ul className='list-decimal p-4 flex flex-col gap-5'>
                <li className='flex flex-wrap'>
                    <p className='text-left text-[#737373]'>
                        <span className='text-primary_color font-bold'>1. Understanding Public Health: </span>
                        Public health encompasses a broad range of efforts aimed at improving and protecting the health of
                        populations. It goes beyond individual health concerns to address factors that influence the
                        well-being of entire communities. This includes promoting healthy behaviors, preventing diseases,
                        and ensuring access to healthcare services.
                    </p>
                </li>

                <li className='flex flex-wrap'>
                    <p className='text-left text-[#737373]'>
                        <span className='text-primary_color font-bold'>2. Preventive Measures: </span>
                        One of the primary objectives of public health awareness is to emphasize preventive measures.
                        Educating people about the importance of vaccinations, regular health screenings, and maintaining
                        a balanced diet can significantly reduce the incidence of various diseases. By adopting preventive
                        behaviors, individuals not only safeguard their own health but also contribute to the overall
                        resilience of their communities.
                    </p>
                </li>

                <li className='flex flex-wrap'>
                    <p className='text-left text-[#737373]'>
                        <span className='text-primary_color font-bold'>3. Combatting Stigma: </span>
                        Public health awareness plays a crucial role in combating stigma associated with certain health conditions.
                        Whether it's mental illness, HIV/AIDS, or substance abuse disorders, stigma can hinder individuals from
                        seeking the care they need. By fostering understanding and empathy, public health campaigns strive to create
                        supportive environments where individuals feel comfortable seeking help without fear of judgment.
                    </p>
                </li>


            </ul>
        </div>
    )
}

export default Awareness