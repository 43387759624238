import React from "react";
import TopBanner from "../components/common/TopBanner";
import banner from "../assets/news/bg.jpg";
import NewsBlogs from "../components/home/blogs/NewsBlogs";

const News = () => {
  return (
    <div>
      <TopBanner title="News" image={banner} />
      <div className="w-full mt-10 ms:mt-20">
        <NewsBlogs />
      </div>
    </div>
  );
};

export default News;
