import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
const SpecialityCard = ({ item, fn }) => {
    return (
        <div className='py-1 px-4 flex items-center gap-x-2 w-fit bg-primary_color rounded-lg text-white'>
            <img src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`} alt="I" />
            <p>{item.label}</p>
            <IoMdClose className='cursor-pointer' onClick={fn} />
        </div>
    )
}
function SelectSpecialities() {
    const [selectedSpecialities, setSelectedSpecialities] = useState([])
    const countries = [
        { code: 'AD', label: 'Andorra', phone: '376' },
        {
            code: 'AE',
            label: 'United Arab Emirates',
            phone: '971',
        },
        { code: 'AF', label: 'Afghanistan', phone: '93' },
        {
            code: 'AG',
            label: 'Antigua and Barbuda',
            phone: '1-268',
        },
    ]
    return (
        <div >
            <Autocomplete
                className='mt-5'
                popupIcon={<MdOutlineKeyboardArrowDown className='text-primary_color' />}
                sx={{ width: 240 }}
                options={countries}
                disableClearable
                componentsProps={{
                    paper: {
                        sx: {
                            width: 350,
                            margin: "auto"
                        }
                    }
                }}
                getOptionLabel={(option) => ""}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                    </Box>
                )}
                size='small'
                onChange={(event, value) => {
                    if (!selectedSpecialities.some(item => item.code === value.code)) {
                        setSelectedSpecialities(prev => [...prev, value]);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Specialties to filter"

                        inputProps={{
                            ...params.inputProps,
                        }}
                        InputLabelProps={{

                            style: {
                                color: '#852065',

                            },
                        }}
                    />
                )}
            />
            <div className='flex flex-wrap mt-4 gap-x-3 gap-y-3'>
                {
                    Array.isArray(selectedSpecialities) && selectedSpecialities.map((item, index) => (
                        <SpecialityCard key={index} item={item} fn={() => {
                            setSelectedSpecialities(selectedSpecialities.filter((_, i) => i !== index));
                        }} />
                    ))
                }
            </div>
        </div>
    )
}

export default SelectSpecialities

