import React from 'react'
function ServiceCard({ title, image, description }) {
    return (
        <div className='w-full rounded-2xl overflow-clip border-b-[7px] border-primary_color'>
            <div className='relative'>
                <div className='py-3 px-7 bottom-0 text-white text-xl bg-primary_color absolute rounded-tr-xl'>
                    <h1>{title}</h1>
                </div>
                <img className='w-full h-[250px] max-md:h-[220px] object-cover ' src={require(`../../assets/services/${image}`)} alt="" />
            </div>
            <div className='py-7  px-6 max-md:px-4 text-left text-paragraph max-md:text-base'>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default ServiceCard
