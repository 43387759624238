import React from "react";

const SideCard = ({ data, selectedData,index }) => {

  return (
    <div
     
      className={`${
        selectedData?.id === data?.id
          ? "bg-primary_color text-white justify-between"
          : "bg-white"
      } my-3 flex items-center w-full h-[65px] px-4 p-2 rounded-lg shadow-xl hover:cursor-pointer gap-2`}
    >
      {/* <img
        src={data?.icon}
        alt="icon"
        className={`${
          selectedData?.id === data?.id
            ? "border rounded w-[30px] m-2 hidden"
            : "bg-primary_color border rounded-lg w-[35px] m-1"
        }`}
      /> */}
      <div
      
        className={`text-start ${
          selectedData?.id === data?.id ? "text-white" : "text-primary_color"
        }`}
      >
        {data?.Heading}
      </div>
      {/* <img
        src={data?.icon2}
        alt="icon"
        className={`${
          selectedData?.id === data?.id
            ? "border rounded-lg w-[35px] m-1 bg-white "
            : "bg-white hidden"
        }`} */}
    </div>
  );
};

export default SideCard;
