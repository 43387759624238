export const pillers = [
    {
        id: 1,
        title: 'Integrity',
        subTitle: 'Transparency and Professionalism',
        description: 'Integrity signifies our commitment to ethical practices, transparency, and maintaining the highest standards of professionalism. To uphold integrity, we intend to prioritise regular training on ethical practices, establish robust governance structures,and encourage a culture of accountability.',
        image: 'integrity.png'
    },
    {
        id: 2,
        title: 'Compassion',
        subTitle: '',
        description: 'Demonstrating empathy, kindness, and understanding towards patients and their families. To fulfil the promise of compassion we plan to adopt the following strategies: Patient centred care, empathy, active listening, emotional support, pain management, patient advocacy, continuity of care, and to treat patients with utmost dignity and respect',
        image: 'compassion.png'
    },
    {
        id: 3,
        title: 'Safety:',
        subTitle: 'Hygiene, Security, and Confidentiality',
        description: "Safety signifies our commitment to providing a secure and protected environment for patients and their well- being. Safety also incorporates hygiene and security emphasising our hospital's dedication to maintaining highest standards of hygiene and infection control protocols and commitment to ensuring the security and privacy of patient information and data, fostering a sense of trust and confidentiality.",
        image: 'safety.png'
    },
    {
        id: 4,
        title: "Expertise:",
        subTitle: "Quality and innovation",
        description: "Expertise symbolises our hospital's highly skilled and knowledgeable healthcare professionals. It represents our focus on providing advanced facilities, innovative treatments, and cutting-edge technologies to ensure the best possible outcomes for our patients and to delivering exceptional medical services, evidence-based treatments, and personalised patient care",
        image: 'expert.jpg'
    }
    ,
    {
        id: 5,
        title: "Community:",
        subTitle: "Engagement and Outreach",
        description: "Engaging with the local community, collaborating with other healthcare providers, participating in health campaigns, and actively promoting wellness and preventive care initiatives to improve the overall health of the community",
        image: 'community.jpg'
    },
    {
        id: 6,
        title: "Inclusivity:",
        subTitle: "Treatment for all",
        description: "This term implies that the hospital aims to provide healthcare services that are welcoming, easily accessible to all, and affordable for a wide range of individuals. By being inclusive, the hospital ensures that its services are available and attainable for everyone, regardless of their background or financial circumstances",
        image: 'inclusive.jpg'
    },
    {
        id: 7,
        title: "Patient Centricity",
        subTitle: "",
        description: "Focusing on individual needs, preferences, and respect for patient autonomy in decision-making, through teamwork, collaboration, and a multidisciplinary approach to healthcare. It represents the hospital's commitment to working together with patients, families, and healthcare professionals, enabling the hospital to leverage the collective expertise and resources, resulting in improved outcomes, enhanced efficiency, and a seamless healthcare experience for patients",
        image: 'centric.jpg'
    }
]