import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import banner2 from "../../../assets/landing/Banner2.png";
import banner1 from "../../../assets/landing/Banner1.jpg";
import banner3 from "../../../assets/landing/banner3.jpg";
import banner4 from "../../../assets/landing/banner4.jpg";

import bannerMob2 from "../../../assets/landing/BannerMob2.png";
import bannerMob1 from "../../../assets/landing/BannerMob1.jpg";
import bannerMob3 from "../../../assets/landing/banner3.jpg";
import bannerMob4 from "../../../assets/landing/BannerMob4.png";

import "./Landing.css";

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoSlideInterval, setAutoSlideInterval] = useState(null);
  const images = [
    { image: banner2, text: "" },
    { image: banner1, text: "" },
    { image: banner3, text: "" },
    { image: banner4, text: "" },
  ];
  const mob = [
    { image: bannerMob2, text: "" },
    { image: bannerMob1, text: "" },
    { image: bannerMob3, text: "" },
    { image: bannerMob4, text: "" },
  ];
  const { t } = useTranslation();
  const carouselRef = useRef(null);

  useEffect(() => {
    startAutoSlide();
    return () => {
      stopAutoSlide();
    };
  }, []);

  const startAutoSlide = () => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    setAutoSlideInterval(interval);
  };

  const stopAutoSlide = () => {
    clearInterval(autoSlideInterval);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  return (
    <div
      className="landing_page h-[430px] md:h-screen w-full relative overflow-hidden"
      ref={carouselRef}
    >
      <div className="w-full mx-auto absolute top-9 hidden md:block">
        <img
          src={images[currentSlide].image}
          alt="bg"
          className="object-contain h-full w-full mt-24"
        />
      </div>
      <div className="w-full mx-auto absolute top-9  md:hidden">
        <img
          src={mob[currentSlide].image}
          alt="bg"
          className="object-contain h-full w-full mt-24"
        />
      </div>

      {/* Carousel */}
      <div className="relative h-full">
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="max-w-common w-full h-full mx-auto px-3 flex md:items-center">
            {/* Right Side image */}

            {/* Left Side Data */}
            <div className="mt-10 md:mt-20 flex flex-col items-start text-white max-md:items-center max-md:justify-center">
              <h1
                className="max-md:text-[38px] text-[60px] font-bold text-wrap w-1/2 max-md:w-10/12 flex 
                  text-start max-md:text-center max-md:leading-[45px] leading-[65px] md:tracking-wide"
              >
                {/* {t("landing.slogan")} */}
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="absolute inset-y-1/2 left-0 transform -translate-y-1/2 flex justify-between w-full px-5">
        <button
          onClick={() => {
            stopAutoSlide();
            prevSlide();
          }}
          className="text-white text-xl focus:outline-none hover:text-gray-300"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={() => {
            stopAutoSlide();
            nextSlide();
          }}
          className="text-white text-xl focus:outline-none hover:text-gray-300"
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
