import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import logo1 from "../../../assets/Hospitals/h1.png";
import logo2 from "../../../assets/Hospitals/h2.png";
import logo5 from "../../../assets/Hospitals/h5.png";
import Card from "./Card";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const GroupHospitals = () => {
  const { t } = useTranslation();
  const words = t("newsBlogs.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  //scroll

  const scrollContainer = useRef();

  const scrollLeft = () => {
    scrollContainer?.current?.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainer?.current?.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  return (
    <div className="">
      {/* heading part */}
      <div className="relative max-w-common mx-auto p-3 flex max-md:flex-col items-center justify-between max-md:gap-y-5 mt-32">
        <div className="">
          <h1 className="text-3xl font-bold md:text-start capitalize max-md:text-2xl">
            {/* {firstPart} */} Our Group of
            <span className="text-primary_color">
              {/* {secondPart} */} Hospitals
            </span>
          </h1>
          <h6 className=" mt-1 text-lg font-light text-left max-md:text-center">
            {/* {t("newsBlogs.subTitle")} */}
            Setting standards in quality health care
          </h6>
        </div>
        <div>
          <Link to={"/hospitals"}>
            <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_colors hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
              {t("seeMore")}
            </h4>
          </Link>
        </div>
      </div>

      <div className="w-full md:pl-3 flex relative">
        <div
          className="hidden sm:block cursor-pointer w-[40px] p-3 h-fit absolute top-[40%] left-3 md:left-14 rounded-full bg-primary_color  text-white outline-none focus:outline-none z-20"
          onClick={scrollLeft}
        >
          <IoIosArrowBack />
        </div>
        <div
          ref={scrollContainer}
          className=" w-full overflow-x-scroll pl-10 max-md:pl-[25px] py-14 hide-scrollbar"
        >
          <div className="w-full flex flex-col md:flex-row justify-start md:ms-20 gap-10">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="md:w-[450px] pe-5 md:pe-0"
            >
              <Card
                name="SP Fort Hospital"
                location="Thiruvananthapuram"
                image={logo1}
                mapLink="https://www.spfort.com/"
                phone="1234567890"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="md:w-[450px] pe-5  md:pe-0"
            >
              <Card
                name="SP HealthPlus"
                location="Thiruvananthapuram"
                image={logo2}
                mapLink="https://www.google.com/maps/dir//Fort,+East+Fort,+Pazhavangadi,+Thiruvananthapuram,+Kerala+695023/@8.4838347,76.8595852,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b05bba02357355b:0xbf23613ad8e0e363!2m2!1d76.9419871!2d8.4838433?entry=ttu"
                phone="1234567890"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="md:w-[450px] pe-5 md:pe-0"
            >
              <Card
                name="SP WELL FORT"
                location="Thiruvananthapuram"
                image={logo5}
                mapLink="https://spwellfort.com/"
                phone="1234567890"
              />
            </div>
          </div>
        </div>
        <div
          className="hidden sm:block cursor-pointer w-[40px] h-fit absolute top-[40%] right-3 md:right-14 rounded-full bg-primary_color p-3 text-white outline-none z-10"
          onClick={scrollRight}
        >
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default GroupHospitals;
