import React from 'react'
import image from '../assets/Homecare/banner.png'
import lactation from '../assets/Homecare/lactation.png'
import heart from '../assets/Homecare/heart.png'
import lungs from '../assets/Homecare/lungs.png'
import joints from '../assets/Homecare/joints.png'
import nurse from '../assets/Homecare/nurse.png'
import head from '../assets/Homecare/head.png'
import star from '../assets/Homecare/star.png'
import ratestar from '../assets/Homecare/ratestar.png'
import quotes from '../assets/Homecare/quotes.png'


const HomeCare = () => {

    const features = [
        { id: 1, img: lactation, caption: 'Mother and baby' },
        { id: 2, img: heart, caption: 'Heart Rehab' },
        { id: 3, img: lungs, caption: 'Lung Rehab' },
        { id: 4, img: nurse, caption: 'Nurse at home' },
        { id: 5, img: joints, caption: 'Joint Rehab' },
        { id: 6, img: head, caption: 'Neuro Rehab' },
    ]

    const faqs = [
        { id: 1, qtn: 'Do you provide eldercare services in Kerala?', ans: 'We are available across Kerala for immediate deployment of caregivers & nurses.' },
        {
            id: 2, qtn: 'Are your caregivers in Kerala qualified and trained to handle elders?',
            ans: `We hire experienced caregivers as well as from verified agencies. Our background checks enable 
        us to certify our caregivers experience and character. All our caregivers go through our Geriatric Training 
        Program from a certified Geriatric Doctor and Nurse.`},
    ]

    const testimonials = [
        {
            id: 1, rating: 3,
            content: `Lorem ipsum dolor sit amet consectetur. Consequat auctor 
        consectetur nunc vitae dolor blandit. Elit enim massa etiam neque laoreet lorem sed.`,
            name: 'John Doe', place: 'Calicut'
        },
        {
            id: 1, rating: 2,
            content: `Lorem ipsum dolor sit amet consectetur. Consequat auctor 
        consectetur nunc vitae dolor blandit. Elit enim massa etiam neque laoreet lorem sed.`,
            name: 'John Doe', place: 'Calicut'
        },
        {
            id: 1, rating: 4,
            content: `Lorem ipsum dolor sit amet consectetur. Consequat auctor 
        consectetur nunc vitae dolor blandit. Elit enim massa etiam neque laoreet lorem sed.`,
            name: 'John Doe', place: 'Calicut'
        },
        {
            id: 1, rating: 5,
            content: `Lorem ipsum dolor sit amet consectetur. Consequat auctor 
        consectetur nunc vitae dolor blandit. Elit enim massa etiam neque laoreet lorem sed.`,
            name: 'John Doe', place: 'Calicut'
        },
    ]

    return (
        <div className='w-full flex flex-col items-center'>
            <div className="mt-24 w-full py-16 px-5 gap-5 bg-no-repeat bg-cover bg-center flex flex-col sm:flex-row justify-around items-center" style={{
                backgroundImage: `url(${image})`,
            }}
            >
                <div className="w-full sm:w-[50vw] h-[65vh] max-md:h-[60vh] flex flex-col justify-center 
            items-start bg-opacity-40 gap-y-4" >
                    <h4 className='text-md md:text-xl font-normal text-white'>SP Medifort</h4>
                    <h1 className='text-3xl md:text-5xl font-medium text-white text-left'>Home Care Services</h1>
                    <p className='text-base md:text-lg font-light text-left text-white'>
                        The evolving healthcare landscape in India has sparked a significant shift towards Homecare -
                        a revolutionary approach that brings healthcare services directly to patients and their families,
                        ensuring comfort and convenience. At SP Medifort Hospital, we have embraced this transformative journey
                        to redefine healthcare delivery in India
                    </p>
                </div>

                <img
                    src={require('../assets/Homecare/granny.png')}
                    alt='old lady'
                    className='sm:w-[20vw] h-[25vh] sm:h-[40vh] object-contain'
                />

            </div>

            <div className='w-full sm:w-[80vw] mt-24 p-5 gap-4 rounded-lg flex flex-col items-center justify-around border border-[#6D6E71]'>
                <h2 className='text-2xl'>Features Provided</h2>
                <div className='mx-12 w-full  py-5 rounded-lg bg-primary_color bg-opacity-10
            flex flex-wrap justify-around items-center'>
                    {
                        features?.map((item, i) => (
                            <div key={i}
                                className='flex flex-col justify-around items-center'>
                                <img src={item.img} alt='pro'/>
                                <span>{item.caption}</span>
                            </div>
                        ))
                    }
                </div>
            </div>

            {/* About */}
            <div className='w-full sm:w-[80vw] mt-12 p-5 gap-5 flex flex-col'>
                <h2 className='text-3xl text-left font-medium'>About</h2>
                <hr className='h-[2px] bg-primary_color' />

                <p className='text-left'>
                    The evolving healthcare landscape in India has sparked a significant shift towards
                    Homecare - a revolutionary approach that brings healthcare services directly to
                    patients and their families, ensuring comfort and convenience. At SP Medifort Hospital,
                    we have embraced this transformative journey to redefine healthcare delivery in India.
                </p>

                <p className='text-left'>
                    With a commitment to delivering unparalleled quality care, SP Medifort Hospital has been
                    at the forefront of this movement for the past several decades. Our mission is clear: to
                    provide top-notch home care services to countless individuals across the nation. Through
                    innovative treatment options and a compassionate approach, SP Medifort Hospital ensures clinical
                    excellence while bringing healthcare right to your doorstep.
                </p>

                <p className='text-left'>
                    Our dedicated team of healthcare professionals works tirelessly to prioritize the
                    well-being of your loved ones, offering them the care and attention they deserve
                    within the familiar and comforting surroundings of home. SP Medifort Hospital stands
                    as a beacon of excellence in the healthcare industry, delivering comprehensive
                    healthcare solutions that cater to the unique needs of each patient.
                </p>

                <p className='text-left'>
                    Join us in our journey as we continue to uphold our commitment to quality, compassion,
                    and convenience in healthcare delivery. Experience the difference with SP Medifort
                    Hospital - where healthcare meets the comfort of home.
                </p>
            </div>

            {/* FAQs */}
            <div className='w-full sm:w-[80vw] mt-12 p-5 gap-5 flex flex-col'>
                <h2 className='text-3xl text-left font-medium'>FAQs</h2>
                <hr className='h-[2px] bg-primary_color' />

                {
                    faqs?.map((item, i) => (
                        <div key={i}
                            className='w-full flex gap-2'
                        >
                            <img src={star} alt='bulletin'
                                className='w-[24px] h-[24px]'
                            />
                            <div className='flex flex-col items-start gap-2 text-left'>
                                <p className='text-primary_color'>{item.qtn}</p>
                                <p className='text-[#6D6E71]'>{item.ans}</p>
                            </div>
                        </div>
                    ))
                }
            </div>

            {/* Testimonials */}
            <div className='w-full mt-12 p-5 gap-5 flex flex-col'>
                <h2 className='text-3xl font-medium'>Testimonials</h2>

                <div className='w-full flex flex-col sm:flex-row flex-wrap gap-4 justify-center'>
                    {/* Cards */}

                    {
                        testimonials?.map((item, i) => (

                            <div key={i}
                                className='w-full sm:w-[38vw] h-fit bg-primary_color text-white rounded-lg p-10
                            flex flex-col items-start gap-1 relative'
                            >
                                <div className='w-fit h-[5vh] flex items-center justify-start mt-2'>
                                    {
                                        Array.from({ length: item?.rating }, (_, i) => (
                                            <img key={i} src={ratestar} alt='Star'
                                                className='w-[24px] h-[24px]'
                                            />
                                        ))
                                    }
                                </div>

                                <p className='w-[80%] h-[14vh] flex items-start mt-5 font-medium
                                text-left overflow-scroll'>
                                    {item?.content}
                                </p>

                                <span className='mt-4 text-sm font-semibold'>{item?.name}</span>

                                <span className='text-xs font-light'>{item?.place}</span>

                                <img src={quotes}
                                    className='w-[100px] absolute top-4 right-16'
                                    alt='quotes'
                                />

                            </div>
                        ))
                    }

                </div>
            </div>


            <div className='w-full lg:w-[80vw] mt-2 p-5 gap-5 flex flex-col lg:flex-row '>
                <div className='w-full lg:w-[55%] h-fit rounded-xl border border-[#6D6E71]
                    flex flex-col justify-around p-6 '>

                    <div className='flex flex-wrap justify-between gap-8'>

                        <div className='w-full lg:w-[45%] flex flex-col items-start gap-2'>
                            <label className='font-medium'>Name*</label>
                            <input
                                placeholder='Name'
                                className='w-full border border-[#999999] p-3 rounded-lg'
                            />
                        </div>

                        <div className='w-full lg:w-[45%] flex flex-col items-start gap-2'>
                            <label className='font-medium'>Pin Code*</label>
                            <input
                                placeholder='Pin Code'
                                className='w-full border border-[#999999] p-3 rounded-lg'
                            />
                        </div>

                        <div className='w-full lg:w-[45%] flex flex-col items-start gap-2'>
                            <label className='font-medium'>Select City*</label>
                            <select
                                className='w-full border border-[#999999] p-3 rounded-lg'
                            >
                                <option value=''>Select City</option>
                            </select>
                        </div>

                        <div className='w-full lg:w-[45%] flex flex-col items-start gap-2'>
                            <label className='font-medium'>Select Service*</label>
                            <select
                                className='w-full border border-[#999999] p-3 rounded-lg'
                            >
                                <option value=''>Select Service</option>
                            </select>
                        </div>
                    </div>

                    <button className='lg:w-fit bg-primary_color text-white text-sm p-2 mt-5 rounded-lg'>Get Enquiry</button>

                </div>

                <div className='w-full lg:w-[43%] flex flex-col items-start gap-3 pt-10 pl-5'>
                    <p className='font-semibold text-xl'>Home Care Enquiry</p>
                    <p className='text-[#6D6E71] text-left text-sm'>
                    The evolving healthcare landscape in India has sparked a significant shift towards Homecare – a revolutionary approach that brings healthcare services directly to patients and their families, ensuring comfort and convenience. At SP Medifort Hospital, we have embraced this transformative journey to redefine healthcare delivery in India
                    </p>
                </div>
            </div>

        </div>
    )
}

export default HomeCare