import React from 'react'

import ExpandingTile from './ExpandingTile';
function ExpandingTilePart() {

    return (
        <div className='border-b-2 '>
            <ExpandingTile title="Emergency Care" content="Emergency care is a medical specialty involving care for undifferentiated, unscheduled patients with illnesses or injuries requiring immediate medical attention. Emergency care is provided by emergency medical services (EMS), first aiders, emergency medical technicians, and paramedics, physicians, emergency nurses, and emergency care assistants." />
        </div>
    )
}

export default ExpandingTilePart
