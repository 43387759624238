export const tests = [
    {
        id: 1,
        name: `SP Medifort Well Woman Silver Checkup`,
        price: 1499,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Random Blood Sugar",
            "Urine Routine Analysis",
            "Total Cholesterol",
            "ECG",
            "X-Ray Chest PA",
            "Ultrasound Whole Abdomen & Pelvis",
            "Gynaecology Consultation OR",
            "Family Physician Consultation"
        ]
    },

    {
        id: 2,
        name: `SP Medifort Well Woman Platinum Checkup`,
        price: 6499,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Glucose-Plasma (fasting & PP)",
            "Glycosylated Hemoglobin (HbA1C)",
            "Lipid Profile",
            "TSH, FT4",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Liver Function Test",
            "Complete Urine Analysis",
            "Serum Calcium",
            "Serum Phosphorus",
            "ECG",
            "X-Ray Chest PA",
            "Ultrasound Whole Abdomen & Pelvis",
            "Ultrasound Breast",
            "Ultrasound Trans-vaginal*",
            "Dexa Scan*",
            "Vitamin D3 25(OH) - Serum",
            "Vitamin B12",
            "Transferrin Saturation (Iron, TIBC, Tsat)",
            "Pap Smear",
            "Family Physician Consultation",
            "Dietician Consultation",
            "One optional Consultation"
        ]
    },
    {
        id: 3,
        name: `SP Medifort Child Health Check (5-12 years) - Superchild`,
        price: 2400,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Random Blood Sugar",
            "Vitamin D3 25(OH) - Serum",
            "Thyroid profile (T3, T4, TSH)",
            "Liver - SGOT, SGPT",
            "Serum - Sodium",
            "Serum - Calcium",
            "Serum - Potassium",
            "Vitals Assessment",
            "Vaccination Assessment",
            "Paediatric Consultation",
            "Dietician Consultation"
        ]
    },
    {
        id: 4,
        name: `SP Medifort Child Health Check (5-12 years) - YoungBud`,
        price: 1500,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Random Blood Sugar",
            "Vitamin D3 25(OH) - Serum",
            "Thyroid profile (T3, T4, TSH)",
            "Dietician Consultation",
            "Vitals Assessment",
            "Vaccination Assessment",
            "Paediatric Consultation"
        ]
    },
    {
        id: 5,
        name: `SP Medifort Superstar Heart Care Package`,
        price: 4999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Diabetic Profile (HbA1C, Fasting BS)",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Lipid Profile",
            "Liver Function Test",
            "Thyroid profile (T3, T4, TSH)",
            "X-Ray Chest PA",
            "Ultrasound Whole Abdomen",
            "ECG",
            "Echo Cardiogram",
            "Cardiac Stress Analysis (TMT)",
            "Height, Weight, BMI, WHR",
            "Diet Counselling",
            "Physician - Family Medicine consultation",
            "Cardiac Consultation",
            "One Optional Consultation"
        ]
    },
    {
        id: 6,
        name: `SP Medifort Superstar Heart Care Package`,
        price: 1999,
        list: [
            "Routine Blood Exam",
            "Diabetic Profile (HbA1C, Random BS)",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Lipid Profile",
            "ECG",
            "Echo Cardiogram screening",
            "Cardiac Stress Analysis (TMT)",
            "Height, Weight, BMI, WHR",
            "Diet Counselling",
            "Physician - Family Medicine consultation",
            "Cardiac Consultation"
          ]
          
    },
    {
        id: 7,
        name: `SP Medifort Healthy Heart Care Package
`,
        price: 999,
        list: [
            "Routine Blood Exam",
            "Diabetic Profile (HbA1C, Random BS)",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Lipid Profile",
            "ECG",
            "Echo Cardiogram screening",
            "Height, Weight, BMI, WHR",
            "Diet Counselling",
            "Physician - Family Medicine consultation",
            "Cardiac Consultation"
          ]
          
    },
    {
        id: 8,
        name: `SP MEDIFORT PREMARITAL HEALTH CHECK`,
        price: 2999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Blood Grouping & Rh Typing",
            "Random Blood Sugar",
            "Glycosylated Hemoglobin (HbA1C)",
            "VDRL",
            "HIV",
            "HBsAg",
            "Complete Urine Analysis",
            "Ultrasound Whole Abdomen & Pelvis",
            "Gynaecology Consultation*",
            "ECG",
            "Chest Xray PA (if indicated)",
            "Family Physician Consultation",
            "Psychologist Consultation"
          ]
          
    },
    {
        id: 9,
        name: `SP Medifort Whole Body Check `,
        price: 9999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Lipid Profile",
            "Diabetic Profile (HbA1C, Fasting BS)",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Serum - Uric acid",
            "Liver Function Test",
            "Complete Urine Analysis",
            "Serum Calcium & Phosphorous, Magnesium",
            "Thyroid profile (T3, T4, TSH)",
            "PSA*",
            "ECG",
            "X-Ray Chest",
            "Ultrasound Whole Abdomen(Screening only)",
            "Echo Cardiogram",
            "Cardiac Stress Analysis (TMT)",
            "Ultrasound Breast for Women*",
            "Pap Smear*",
            "Dexa Scan*",
            "Vitamin D3 25(OH) - Serum",
            "Vitamin B12",
            "Transferrin Saturation (Iron, TIBC, Tsat)",
            "Physician - Family Medicine consultation",
            "Cardiac Consultation",
            "One Optional Consultation",
            "ENT Consultation",
            "Physiotherapist  Consultation",
            "Diet Counselling"
          ]
          
    },
    {
        id: 10,
        name: `SP Medifort Senior Citizen Check`,
        price: 5999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Diabetic Profile (HbA1C, Fasting BS)",
            "Lipid Profile",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Serum Calcium, Sodium, Potassium",
            "Liver Function Test",
            "PSA for Men",
            "Thyroid profile (T3, T4, TSH)",
            "Complete Urine Analysis",
            "Serum - Uric acid",
            "Stool Routine",
            "Stool Occult Blood",
            "ECG",
            "X-Ray Chest",
            "Ultrasound Screening Whole Abdomen",
            "Dexa Scan*",
            "Pap Smear*",
            "Vitamin D3 25(OH) - Serum",
            "Physiotherapy Consultation",
            "Diet Counselling",
            "Echo Cardiogram",
            "Cardiac Stress Analysis (TMT)*",
            "ENT Consultation",
            "Consultation & Review with Physician"
          ]
          
    },
    {
        id: 11,
        name: `SP Medifort Platinum Health Check`,
        price: 12999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Blood Grouping & Rh Typing",
            "Glucose-Plasma (fasting)",
            "Glucose-Plasma (Post Prandial)",
            "Glycosylated Hemoglobin (HbA1C)",
            "Serum Creatinine",
            "Blood Urea",
            "Serum Uric Acid",
            "Lipid Profile",
            "Apo Lipoprotein A1-Serum",
            "Apo Lipoprotein B-Serum",
            "Lp (A): Lipoprotein(A)- Serum",
            "Homocysteine-Plasma",
            "Plasma Fibrinogen",
            "Hs-CRP",
            "Liver Function Test",
            "Serum Calcium",
            "Phosphorus,Inorganic-Serum",
            "Serum Electrolytes",
            "TSH",
            "Vitamin D3(25(OH)D3)- Serum",
            "Complete Urine Analysis",
            "Urine Culture & Sensitivity",
            "HBsAg",
            "Anti HCV Elisa",
            "PSA for Men (50+ age)",
            "Stool Occult Blood(50+ age)",
            "Stool Routine",
            "X-Ray Chest",
            "ECG",
            "2 D-Echo with colour Doppler",
            "Cardiac Stress Analysis (TMT)",
            "Ultrasound Whole Abdomen",
            "Dexa Scan*",
            "Ultrasound Breast for Women",
            "Diet Counselling",
            "Surgical Consultation for Men",
            "Pap Smear*",
            "ENT Consultation",
            "Cardiac Consultation",
            "Consultation & Review with Physician"
          ]
          
    },
    {
        id: 12,
        name: `SP Medifort Diabetes Ultra Checkup`,
        price: 4999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Diabetic Profile (HbA1C, Fasting BS, PPBS)",
            "Lipid Profile",
            "Renal Profile (Urea, Serum Creatinine, Uric acid)",
            "Serum - Uric acid",
            "Electrolytes",
            "Serum Calcium",
            "Liver Function Test",
            "Thyroid profile (T3, T4, TSH)",
            "Urine microalbuminuria",
            "ECG",
            "Cardiac Stress Analysis (TMT)",
            "Echo Cardiogram",
            "Ultrasound Whole Abdomen",
            "Arterial Doppler",
            "Neuropathy evaluation",
            "Retinopathy Evaluation",
            "X-Ray Chest PA",
            "Cardiac Consultation",
            "Diabetologist Consultation",
            "Podiatrist consultation",
            "Family Physician Consultation"
          ]
          
    },
    {
        id: 13,
        name: `SP Medifort Diabetes Standard Checkup`,
        price: 999,
        list: [
            "Complete Hemogram (CBC, ESR)",
            "Diabetic Profile (HbA1C, Fasting BS, PPBS)",
            "Complete Urine Analysis",
            "Serum Creatinine, Urea",
            "LFT",
            "Family Physician Consultation"
          ]
          
    },
    {
        id: 14,
        name: `SP Medifort Kidney Checkup`,
        price: 999,
        list: [
            "Blood Sugar Random",
            "Complete Urine Analysis",
            "Serum Creatinine, Urea",
            "Ultrasound KUB",
            "Family Physician Consultation",
            "Nephrologist Consultation"
          ]
          
    },

]