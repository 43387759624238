import heart from "../../../assets/specialities/heart_organ 1.png"
import heart2 from "../../../assets/specialities/heart_organ 2.png"
import Banner1 from "../../../assets/specialities/Banner1.png"
import sp1 from '../../../assets/specialities/1.jpg'
import sp2 from '../../../assets/specialities/2.jpg'
import sp3 from '../../../assets/specialities/3.jpg'
import sp4 from '../../../assets/specialities/4.jpg'
import sp5 from '../../../assets/specialities/5.jpg'
import sp6 from '../../../assets/specialities/6.jpg'
import sp7 from '../../../assets/specialities/7.jpg'
import sp8 from '../../../assets/specialities/8.webp'
import sp9 from '../../../assets/specialities/9.jpg'
import sp10 from '../../../assets/specialities/10.jpg'
import sp11 from '../../../assets/specialities/11.jpg'
import sp12 from '../../../assets/specialities/12.jpeg'

export const speciality = [
    {
        id: 1,
        Heading: "3D Cath Lab (GE Allia) ",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `We are proud to have the first 3D Cath Lab (GE Allia) with Artificial Intelligence, in South Asia. A 
                          trusted state-of-the-art assistant for image guided therapies assists you to perform tasks naturally 
                          in a personalized workplace, so that nothing comes between you and the patient for an effortless and 
                          efficient procedure. Augment imaging. Enhance experience. Improve outcomes. `
            }
        ],
        image: sp1
    },
    {
        id: 2,
        Heading: "Fully Robotic (Zeiss Kinevo) Neuro-microscope system",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `We have the first Fully Robotic (Zeiss Kinevo) Neuro-microscope system in South
                Kerala. The Robotic Visualization System® – 
                KINEVO® 900 from ZEISS combines optical and digital visualization modalities, 
                offers ZEISS QEVO – the unique Micro-Inspection Tool and will impress you with its Surgeon-Controlled Robotics. 
                Experience Surgeon-Controlled Robotics to discover a new level of precise positioning. This innovation enables intelligent positioning functions – 
                reducing manual hassle. And, helping you to focus on your treatment. ZEISS KINEVO 900 delivers a lot more positioning precision with a lot less effort.`
            }
        ],
        image: sp2
    },
    {
        id: 3,
        Heading: "Patient-friendly fully motorized X-ray suite",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `At SP Medifort, we're proud to offer a patient-friendly fully motorized X-ray suite,
                 designed with your comfort and convenience in mind. Our state-of-the-art suite features advanced
                  technology and ergonomic design, ensuring a smooth and hassle-free imaging experience for patients
                   of all ages. With fully motorized equipment, our radiographers can easily adjust positioning 
                   and angles to capture high-quality images quickly and accurately, minimizing discomfort and reducing
                    the need for retakes. This innovative system also allows for precise control and customization
                    , ensuring optimal imaging results. `
            }
        ],
        image: sp3
    },
    {
        id: 4,
        Heading: "24x7 remote patient monitoring",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `We offer 24x7 remote patient monitoring for clinical support, the first in South Kerala, 
                ensuring that patients receive continuous care and attention, even outside traditional
                healthcare settings. Our remote monitoring system utilizes advanced technology to track vital signs,
                symptoms, and other key health indicators in real-time, allowing our clinical team to promptly intervene 
                and provide support as needed. Through remote monitoring, patients can enjoy the convenience of receiving 
                personalized care from the comfort of their own homes while staying connected to their healthcare providers. 
                Our system allows for timely identification of any changes or concerns in patient health, enabling proactive 
                intervention and preventing potential complications.`
            }
        ],
        image: sp4
    },
    {
        id: 5,
        Heading: "Versius Surgical Robot:",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `It is the patented V-wrist technology that enables Versius to 
                be a surgical robot like no other. This innovative technology, allows Versius to 
                be small, modular and portable, making it easy for surgeons to adopt, easy to 
                move around a hospital, and ultimately maximizes utilization. The 360 degrees 
                of wrist rotation also allows the surgical instruments to be small, leading to 
                smaller incisions for the patient. With Versius, you can place your ports where you 
                want them, to best suit the needs of each patient. This allows you to operate the 
                way you did laparoscopically, with all the benefits of robotic-assisted surgery.`
            }
        ],
        image: sp5
    },
    {
        id: 6,
        Heading: "Robotic Navigation for Neurosurgery",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `A robotic navigation system for image-guided neurosurgery, 
                which can be applied to the treatment of Parkinson's disease and biopsy 
                of brain tumors. The system integrates a computer for real-time display 
                of brain anatomy, a magnetic tracking device for measuring the positions 
                and orientations of surgical instruments, and a robot manipulator for guiding 
                surgical instruments to the preplanned positions and orientations. The 
                computer display of brain anatomy offers a convenient tool for surgeons 
                to diagnose brain disease and to plan safe surgical paths; while the tracking 
                device assists the robot manipulator to automatically guide surgical instruments 
                to the preplanned direction.
`
            }
        ],
        image: sp6
    },
    {
        id: 7,
        Heading: "170 level-D cubicle ICU beds:Can be written tomorrow",
        content: [
            {
                id: 1,
                subHeading: "",
                content: ``
            }
        ],
        image: sp7
    },
    {
        id: 8,
        Heading: "Dedicated specialty ICUs",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `At SP Medifort, we're proud to offer dedicated specialty ICUs, providing specialized 
                care for patients with complex medical needs. Our specialty ICUs are staffed by highly trained 
                healthcare professionals, including intensivists, nurses, and specialists, who are experts in 
                managing specific medical conditions. Whether it's a cardiac ICU for patients with heart-related 
                conditions, a neuro ICU for those with neurological disorders, or a surgical ICU for post-operative 
                care, each unit is equipped with state-of-the-art technology and resources to meet the unique needs 
                of our patients. Our specialty ICUs provide comprehensive, round-the-clock care, with specialized 
                treatment protocols tailored to each patient's condition. This includes advanced monitoring, specialized 
                interventions, and multidisciplinary care teams working collaboratively to achieve the best possible outcomes.`
            }
        ],
        image: sp8
    },
    {
        id: 9,
        Heading: "30 Emergency care beds with Burns Unit",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `At SP Medifort, we're equipped with a specialized Burns Unit 
                comprising 30 emergency care beds, ensuring immediate and comprehensive 
                treatment for patients with burn injuries. Our Burns Unit is staffed by a 
                dedicated team of burn care specialists, including surgeons, nurses, and 
                therapists, who are trained to provide expert care for patients with 
                varying degrees of burn severity. With state-of-the-art facilities and 
                equipment, our Burns Unit offers advanced treatment modalities, including 
                wound care, skin grafting, and pain management techniques tailored to each 
                patient's individual needs. We prioritize rapid assessment and intervention, 
                ensuring timely care to minimize complications and promote optimal healing. 
                In addition to acute burn care, our multidisciplinary team provides ongoing 
                support and rehabilitation services to help patients recover and regain function
                following their injuries.`
            }
        ],
        image: sp9
    },
    {
        id: 10,
        Heading: "Bone Banks",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `At SP Medifort, we're proud to offer a Bone Bank, providing a vital resource 
                for patients in need of bone grafts for various orthopedic and reconstructive procedures. 
                Our Bone Bank is dedicated to collecting, processing, and storing donated bone tissue 
                from generous donors, ensuring a readily available supply for patients requiring bone grafts. 
                Our Bone Bank operates with strict adherence to regulatory standards and quality assurance 
                protocols, ensuring the safety and efficacy of all donated bone tissue.`
            }
        ],
        image: sp10
    },
    {
        id: 11,
        Heading: "1.5 Tesla MRI:",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `A 1.5T MRI scanner is more than adequate to generate clear 
                images and provide evidence for diagnosis and prognosis. 1.5T MRI scanner 
                is designed to increase your efficiency, improve productivity and maximize 
                your diagnostic potential while keeping the patient experience at the forefront. 
                The 1.5T MRI scanner provides a masterful balance of comfort and productivity, 
                with its patient-friendly design and system utility. 1.5T MRI are tried and 
                true machines that produce high quality imaging results. Longer sequences 
                with 1.5T scans can also greatly improve image quality..`
            }
        ],
        image: sp11
    },
    {
        id: 12,
        Heading: "128-slice CT",
        content: [
            {
                id: 1,
                subHeading: "",
                content: `A 128-slice CT scan is a type of computed tomography (CT) 
                imaging technology that utilizes 128 detectors to capture multiple 
                images of the body. This 128-Slice CT scan is a non-invasive diagnostic 
                test that uses X-rays to produce detailed cross-sectional images of the body. 
                128-slice CT scan is a type of computed tomography (CT) imaging technology that 
                offers several benefits over other types of CT scans. Some of the benefits of 
                128 slice CT scan include:`
            },
            {
                id: 2,
                subHeading: "Higher Resolution: ",
                content: `28 slice CT scan offers higher spatial resolution 
                compared to lower-slice CT scans, which means that it can produce 
                more detailed and precise images of the body. This can be 
                particularly important for diagnosing and monitoring certain medical conditions`
            },
            {
                id: 3,
                subHeading: "Faster Scanning Time:",
                content: `128 slice CT scan can produce high-quality images of the body in a 
                shorter amount of time compared to lower-slice CT scans. This means that 
                patients spend less time in the scanning machine, reducing their discomfort 
                and anxiety during the procedure.`
            },
            {
                id: 4,
                subHeading: "Reduced Radiation Exposure:",
                content: `128 slice CT scan uses advanced dose reduction techniques, 
                which can help reduce the amount of radiation exposure to patients while 
                still producing high-quality images.`
            },
            {
                id: 5,
                subHeading: "Improved Accuracy:",
                content: `The high resolution and fast scanning time of 128 slice CT scan can 
                help improve the accuracy of diagnosis and treatment planning for various medical conditions. 
                This can lead to better patient outcomes and improved quality of life.
                Overall, a 128-slice CT scan is a valuable tool in the field of medical imaging that 
                can offer a high level of diagnostic accuracy and efficiency while minimizing patient discomfort and radiation exposure`
            },
        ],
        image: sp12
    },
]