import React from 'react';
import { BsThreeDots } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { IoMdImage } from "react-icons/io";
import { BiSolidFilePdf } from "react-icons/bi";
import { BsFileEarmarkBreakFill } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { FaHeartbeat } from "react-icons/fa";
import { GrDocumentTest } from "react-icons/gr";
import { GiHeartPlus } from "react-icons/gi";
import { IoPersonSharp } from "react-icons/io5";
import { FaCashRegister } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa";

const Profile = () => {
    const tabArray = [
        {id:1, name: 'Appointments', icon:<CiCalendar size={24} color='#852065'/>,link:''},
        {id:2, name: 'Doctors', icon:<FaUserDoctor size={24} color='#852065'/>,link:''},
        {id:3, name: 'Treatment', icon:<FaHeartbeat size={24} color='#852065'/>,link:''},
        {id:4, name: 'Test & Result', icon:<GrDocumentTest size={24} color='#852065'/>,link:''},
        {id:5, name: 'Vital Signs', icon:<GiHeartPlus size={24} color='#852065'/>,link:''},
        {id:6, name: 'Partner profile', icon:<IoPersonSharp size={24} color='#852065'/>,link:''},
        {id:7, name: 'Billing', icon:<FaCashRegister size={24} color='#852065'/>,link:''},
        {id:8, name: 'Consent Forms', icon:<FaWpforms size={24} color='#852065'/>,link:''},
        // {id:9, name: '', icon:</>,link:''},
        // {id:10, name: '', icon:</>,link:''},
    ]
    return (
        <div
            className='w-full h-auto mt-[12rem] flex justify-center items-center px-1 sm:px-10'
        >
            <div
                className='w-full h-auto bg-white shadow-lg rounded-xl p-3 sm:p-10 flex flex-col items-center gap-4 '
            >
                <div className='w-full flex flex-col sm:flex-row justify-between items-center gap-3 px-1 sm:px-4'>
                    <h2 className='text-primary_color text-2xl font-bold'>Patient Profile</h2>
                    <button className='w-[80%] lg:w-fit bg-primary_color text-white p-3 rounded-lg' >Download Now</button>
                </div>

                <div className='w-full flex flex-col sm:flex-row p-1 sm:p-4'>
                    <div className='w-full sm:w-[40%] h-auto gap-6 flex flex-col p-5 '>

                        <div className='w-full h-[44vh] rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-col p-6 gap-4'>
                            <span className='absolute top-5 right-5 cursor-pointer'><BsThreeDots size={24} color='#D9D9D9' /></span>

                            <div className='flex items-center gap-4'>
                                <img
                                    src={require('../../assets/patient/patient.jpeg')}
                                    className='w-[70px] h-[70px] rounded-full object-cover'
                                    alt='patient'
                                />

                                <span className='font-bold'>Mr. Manu M</span>
                            </div>

                            <div className='flex flex-col items-start text-left gap-3'>
                                <h1 className='font-bold'>Contact Details:</h1>
                                <p className='flex flex-wrap items-center gap-2'><FaPhone color='#852065' size={16} /> 987654321</p>
                                <p className='flex flex-wrap  items-center gap-2'><IoMail color='#852065' size={16} /> test@gmail.com</p>
                                <p className='flex flex-wrap  items-center gap-2'><FaHome color='#852065' size={16} /> test villa, test street, textplace P.O</p>

                            </div>

                        </div>

                        <div className='w-full h-auto rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-col p-5 sm:p-6 gap-4'>
                            <span className='absolute top-5 right-5 cursor-pointer'><BsThreeDots size={24} color='#D9D9D9' /></span>

                            <div className='flex flex-col items-start text-left gap-3'>
                                <h1 className='font-bold'>Latest Lab Results:</h1>
                                <p className='flex flex-wrap items-center gap-2'><IoMdImage color='#852065' size={16} />Over View Scan, 25 Nov </p>
                                <p className='flex flex-wrap  items-center gap-2'><BiSolidFilePdf color='#852065' size={16} /> Blood Test, 25 Nov </p>
                                <p className='flex flex-wrap  items-center gap-2'><IoMdImage color='#852065' size={16} /> Full body Scan, 25 Nov </p>
                                <p className='flex flex-wrap  items-center gap-2'><BsFileEarmarkBreakFill color='#852065' size={16} /> Over View Scan, 25 Nov </p>

                            </div>

                            <div className='flex flex-col sm:flex-row items-center gap-4'>
                                <button className='w-[80%] lg:w-fit border border-primary_color text-primary_color p-3 rounded-lg font-semibold' >Upload Files</button>
                                <span className='text-primary_color font-semibold'>View all </span>

                            </div>

                        </div>

                    </div>

                    <div className='w-full sm:w-[60%] h-auto gap-6 flex flex-col p-5 '>
                        <div className='w-full h-auto rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-col p-10 gap-4'>
                            <span className='absolute top-5 right-5 cursor-pointer'><BsThreeDots size={24} color='#D9D9D9' /></span>

                            <div className='flex flex-col items-start text-left gap-3'>
                                <h1 className='font-bold'>Overview:</h1>
                                <div className='w-full flex flex-col '>
                                    <div className='w-full flex flex-wrap items-center justify-between'>

                                        <div className='lg:w-1/4 flex flex-col'>
                                            <span className='text-[#C2C2C2]'>Gender:</span>
                                            <span className='font-bold'>Male</span>
                                        </div>

                                        <div className='lg:w-1/4 flex flex-col'>
                                            <span className='text-[#C2C2C2]'>Date of Birth:</span>
                                            <span className='font-bold'>22/06/1993</span>
                                        </div>
                                        <div className='lg:w-1/4 flex flex-col'>
                                            <span className='text-[#C2C2C2]'>Next of Kin:</span>
                                            <span className='font-bold'>Manoj M</span>
                                        </div>
                                    </div>

                                    <div className='w-full flex flex-wrap items-center justify-between'>


                                        <div className='lg:w-1/4 flex flex-col'>
                                            <span className='text-[#C2C2C2]'>Previous visit:</span>
                                            <span className='font-bold'>22/06/1993</span>
                                        </div>
                                        <div className='lg:w-1/4 flex flex-col'>
                                            <span className='text-[#C2C2C2]'>Next visit:</span>
                                            <span className='font-bold'>22/06/1993</span>
                                        </div>
                                        <div className='lg:w-1/4 flex flex-col'>
                                            <span className='text-[#C2C2C2]'>Allergies:</span>
                                            <span className='font-bold'>None</span>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='w-full h-auto rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-wrap justify-center p-10 gap-8'>
                        {
                            tabArray?.map(tab=>(
                                <div key={tab.id} className='w-[80vw] sm:w-[18vw] h-[10vh] flex items-center justify-around 
                            border border-primary_color rounded-xl'>
                                    <span
                                        className='bg-[#f0d5e7] p-2 rounded-full flex items-center justify-center'>
                                        {tab.icon}
                                    </span>
                                    <span className='font-bold'>{tab.name}</span>
                                    <MdKeyboardArrowRight size={24} color='#AD9D9D' />
                                </div>

                            ))
                        }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Profile