import React from 'react';
import image from '../assets/healthtalk/pancakes.png'

const HealthTalk = () => {
    return (
        <div className="w-full mt-40 flex flex-col">
            <div className='w-full flex flex-col items-center gap-2 mt-10 p-5'>
                <h5>
                    Articles, videos, downloads
                </h5>
                <h1 className='font-medium text-[50px] '>
                    Health Talk
                </h1>
            </div>

            <div className='w-full h-[120vh] sm:h-[75vh] p-5 flex flex-col sm:flex-row gap-4'>
                <div style={{ backgroundImage: `url(${image})` }}
                    className='w-full h-full bg-no-repeat bg-cover bg-center 
                    rounded-xl relative '>

                    <div className='w-full h-[25vh] absolute bottom-5 flex flex-col items-start justify-around text-white 
                    pl-5 pr-2'>
                        <span className='w-fit bg-primary_color text-white p-2 rounded-lg text-sm'>
                            Article
                        </span>

                        <span className='text-[1.2em] md:text-[2em] block text-left'>
                            Nourishing Recovery Amidst Medical Challenges
                        </span>

                        <p className='text-sm'>
                            Prof.Mark Zuckerberg
                            <div className='w-[10px] h-[10px] mx-[12px] bg-[#727272] rounded-full inline-block'></div>
                            Mar 21, 2023
                        </p>
                    </div>
                </div>

                <div className=' w-full flex flex-col gap-4 '>
                    <div style={{ backgroundImage: `url(${image})` }}
                        className='w-full h-[30vh] sm:h-2/5 bg-no-repeat bg-cover bg-center relative
                        rounded-xl'>
                        <div className='w-full h-[15vh] absolute bottom-3 flex flex-col items-start justify-around text-white 
                            pl-5 pr-2'>
                            <span className='w-fit bg-primary_color text-white p-2 rounded-lg text-xs'>
                                Article
                            </span>

                            <span className='text-[1.2em] md:text-[1.5em] block text-left'>
                                Nourishing Recovery Amidst Medical Challenges
                            </span>

                            <p className='text-xs'>
                                Prof.Mark Zuckerberg
                                <div className='w-[7px] h-[7px] mx-[8px] bg-[#727272] rounded-full inline-block'></div>
                                Mar 21, 2023
                            </p>
                        </div>
                    </div>

                    <div style={{ backgroundImage: `url(${image})` }}
                        className='w-full h-[30vh] sm:h-3/5 bg-no-repeat bg-cover bg-center relative
                        rounded-xl border border-black'>
                        <div className='w-full h-[15vh] absolute bottom-3 flex flex-col items-start justify-around text-white 
                            pl-5 pr-2'>
                            <span className='w-fit bg-primary_color text-white p-2 rounded-lg text-xs'>
                                Article
                            </span>

                            <span className='text-[1.2em] md:text-[1.5em] block text-left'>
                                Nourishing Recovery Amidst Medical Challenges
                            </span>

                            <p className='text-xs'>
                                Prof.Mark Zuckerberg
                                <div className='w-[7px] h-[7px] mx-[8px] bg-[#727272] rounded-full inline-block'></div>
                                Mar 21, 2023
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            {/* Videos */}
            <div className='w-full h-auto flex flex-col p-8'>
                <div className='w-full h-[15vh] flex flex-col sm:flex-row justify-between'>
                    <div className='w-fit h-full flex flex-col items-start'>
                        <h5>Informative videos</h5>
                        <h3 className='font-medium text-[20px] sm:text-[30px]'>Videos</h3>
                    </div>
                    <button className='w-full sm:w-fit h-fit p-2 rounded-lg border border-primary_color'>See all videos</button>
                </div>

                <div className='w-full h-fit p-5 flex flex-col sm:flex-row gap-8 mt-4 sm:mt-0'>
                    <video class="w-full sm:max-w-[45vw] rounded-lg shadow-md" controls poster={require('../assets/healthtalk/thumbnail.png')}>
                        <source src={""} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <video class="w-full sm:max-w-[45vw] rounded-lg shadow-md" controls poster={require('../assets/healthtalk/thumbnail.png')}>
                        <source src={""} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            {/* Latest Articles */}
            <div className='w-full h-auto flex flex-col p-8'>
                <div className='w-full h-[15vh] flex flex-col sm:flex-row justify-between'>
                    <div className='w-fit h-full flex flex-col items-start'>
                        <h5>Informative Articles</h5>
                        <h3 className='font-medium text-[20px] sm:text-[30px]'>Latest Articles</h3>
                    </div>
                    <button className='w-full sm:w-fit h-fit p-2 rounded-lg border border-primary_color'>See all articles</button>
                </div>

                <div className='w-full flex flex-col sm:flex-row flex-wrap justify-around gap-8 mt-4 sm:mt-0'>
                    {
                        [1, 2, 3, 4].map((item, i) => (

                            <div key={i} className='sm:w-[20vw] flex flex-col rounded-t-xl'>
                                <img
                                    src={require('../assets/healthtalk/smpancake.png')}
                                    className='h-[30vh] rounded-t-xl '
                                />
                                <div className='flex flex-col items-start h-fit py-2'>
                                    <span className='text-primary_color font-medium'>Article</span>
                                    <span className='text-left'>Nourishing Recovery Amidst Medical Challenges</span>
                                    <p className='text-xs text-[#929292]'>
                                        Prof.Mark Zuckerberg
                                        <span class="inline-block w-[1px] h-[10px] mx-[5px] bg-black"></span>
                                        Mar 21, 2025
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            {/* Latest Researches */}
            <div className='w-full h-auto flex flex-col p-8'>
                <div className='w-full h-[15vh] flex flex-col sm:flex-row justify-between'>
                    <div className='w-fit h-full flex flex-col items-start'>
                        <h5>Informative Researches</h5>
                        <h3 className='font-medium text-[20px] sm:text-[30px]'>Latest Researches</h3>
                    </div>
                    <button className='w-full sm:w-fit h-fit p-2 rounded-lg border border-primary_color'>See all researches</button>
                </div>

                <div className='w-full flex flex-col sm:flex-row flex-wrap justify-around gap-8 mt-4 sm:mt-0'>
                    {
                        [1, 2, 3, 4].map((item, i) => (

                            <div key={i} className='sm:w-[20vw] flex flex-col rounded-t-xl'>
                                <img
                                    src={require('../assets/healthtalk/smpancake.png')}
                                    className='h-[30vh] rounded-t-xl '
                                />
                                <div className='flex flex-col items-start h-fit py-2'>
                                    <span className='text-primary_color font-medium'>Research</span>
                                    <span className='text-left'>Nourishing Recovery Amidst Medical Challenges</span>
                                    <p className='text-xs text-[#929292]'>
                                        Prof.Mark Zuckerberg
                                        <span class="inline-block w-[1px] h-[10px] mx-[5px] bg-black"></span>
                                        Mar 21, 2025
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <hr className=' mx-5 h-[2px] bg-primary_color' />

            {/* Brochures */}

            <div className="w-full mt-5 flex flex-col items-center">
                <div className='w-full flex flex-col items-center gap-[5px] p-5'>
                    <h5 className='text-[10px] sm:text-[15px]'>
                        Informative Brochures
                    </h5>
                    <h1 className='font-medium text-[20px] sm:text-[30px] '>
                        Downloads
                    </h1>
                </div>

                <div className='w-full sm:w-[70%] flex flex-col sm:flex-row flex-wrap justify-around gap-8 items-center p-5 sm:p-0'>

                    {/* brochure */}
                    {
                        [1,2,3,4].map((item,i)=>(

                        <div key={i} className='w-full sm:w-fit rounded-lg p-2 flex gap-4 border border-[#555]'>
                            <img 
                            src={require('../assets/healthtalk/brochure.png')} 
                            className='w-[70px] h-[70px]'
                            />

                            <div className='flex flex-col items-start pr-4'>
                                <span className='text-lg font-medium'>Brochure 1</span>
                                <span className='text-sm'>1.97 mb</span>
                                <span className='text-[#606060] text-sm mt-[5px]'>Click to download</span>
                            </div>
                        </div>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default HealthTalk