import React from "react";
import NavBar from "./nav/NavBar";
import Footer from "./footers/Footer";
import { useLocation } from "react-router-dom";
import CallBtn from "../components/home/CallBtn";
import DocFloatIcon from "../components/floats/DocFloatIcon";
import CallUsFloat from "../components/floats/CallUsFloat";

const Layout = ({ children }) => {
  const location = useLocation();
  const authroutes = ["sign-up", "login"];
  const isAuthRoute = authroutes.some((route) =>
    location.pathname.includes(route)
  );
  return (
    <div>
      <nav className="">
        <NavBar />
      </nav>
      <section className="overflow-hidden ">{children}</section>
      <footer className={`${isAuthRoute ? "mt-0" : "mt-14 md:mt-28" }  bg-[#F6F6F8]`}>
        <Footer />
      </footer>

      <CallUsFloat/>
      <DocFloatIcon/>
      <CallBtn />
      
    </div>
  );
};

export default Layout;
