import React, { useEffect, useState } from "react";
import Card from "./Card";
import { data } from "../../data/Leaders";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Aos from "aos";

const Leader = () => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(5); // Number of visible cards at a time

  const handleNext = () => {
    if (visibleIndex + visibleCards < data.length) {
      setVisibleIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (visibleIndex > 0) {
      setVisibleIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < 768) {
        setVisibleCards(1); // Change visible cards for mobile screens
      } else {
        setVisibleCards(5); // Reset visible cards for larger screens
      }
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    Aos.refresh();
  }, []);

  return (
    <div className="w-full mx-w-screen-xl overflow-hidden" id="leadership">
      {/* <img
        src={require("../../assets/newImages for home/Vector Smart Object (Double Click to Edit)_.png")}
        alt="bg_image"
        className="left-0 absolute w-full object-cover h-screen"
      /> */}
      <div className="flex flex-col w-full  md:items-center justify-center relative px-5  md:px-10">
        <h1 className="font-bold text-2xl md:text-headings font-sans z-20 max-w-common  mx-auto lg:pb-10">
          Our <span className="text-primary_color">Leadership</span> Team
        </h1>
        <div
          className="w-full md:flex h-full md:items-center relative "
          data-aos="fade-left"
        >
          <div className="no-scrollbar w-full h-full overflow-auto flex justify-center items-center cursor-pointer md:gap-3 focus:outline-none ">
            <div
              className="absolute left-0 md:left-5 rounded-full bg-primary_color p-3 text-white outline-none focus:outline-none md:hidden"
              onClick={handlePrev}
            >
              <IoIosArrowBack />
            </div>
            {data
              .slice(visibleIndex, visibleIndex + visibleCards)
              .map((items, index) => (
                <div key={index}>
                  <Card
                    title={items.title}
                    content={items.content}
                    color={items.color}
                    image={items.image}
                  />
                </div>
              ))}
            <div
              className="absolute right-0 md:right-5 rounded-full bg-primary_color p-3 text-white outline-none md:hidden"
              onClick={handleNext}
            >
              <IoIosArrowForward />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leader;
