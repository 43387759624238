import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Blog } from "../data/Blogs";
import NewsBlogs from "../components/home/blogs/NewsBlogs";

const NewsDetailedView = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = Blog.find((items) => items?.id == id);
    setData(data);
  }, [id]);

  return (
    <div className="py-28 md:py-36 max-w-[1280px] mx-auto p-3 ">
      <div className="mt-10 pb-5 md:pb-10">
        <h1 className="text-start font-bold text-2xl md:text-5xl text-primary_color capitalize">
          {data?.title}
        </h1>
        <div className="text-start pt-2">
          <span className="font-medium ">Post Date :</span> {data?.postDate}
        </div>
      </div>
      <img
        src={data?.banner}
        alt="banner"
        className="w-full object-cover md:h-[300px] rounded-lg"
      />

      <div className="mt-10 min-h-[100px] text-start">
        {data?.content?.map((items) => (
          <div key={items?.id}>
            <h1 className="text-xl font-medium my-2"> {items?.subheading}</h1>
            <p>{items?.para}</p>

            {/* Check if subData exists and has SubContent (case-sensitive) */}
            {items?.subData &&
              items?.subData?.length > 0 && ( // Ensure subData is not empty
                <div className="py-3">
                  {items?.subData?.map((subData) => (
                    <>
                      <h2
                        className={`font-medium text-lg ${
                          subData?.heading && "py-2 ps-3"
                        }`}
                      >
                        {subData?.heading}
                      </h2>
                      {/* Check if SubContent exists (case-sensitive) */}
                      {subData?.SubContent && ( // Ensure SubContent exists
                        <ul className="ps-7 list-disc">
                          {subData?.SubContent?.map((data) => (
                            <li key={data?.id}>{data?.content}</li>
                          ))}
                        </ul>
                      )}
                      <ul className="ps-7 list-disc">
                        <li>
                          {" "}
                          <span className="font-[500]">{subData?.b} </span>
                          {subData?.content}
                        </li>
                      </ul>
                    </>
                  ))}
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsDetailedView;
