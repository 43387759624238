import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { FaSearch } from 'react-icons/fa';
import { Autocomplete, TextField, autocompleteClasses } from '@mui/material'
import { IoMdClose } from 'react-icons/io';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 500,
    borderRadius: 2,
    p: 4,
};


function SearchBySymptom() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedSymptoms, setSelectedSymptoms] = useState([])
    const top100Films = [
        { label: 'The Shawshank Redemption', id: 1 },
        { label: 'The Godfather', id: 2 },
        { label: 'The Godfather: Part II', id: 3 },
        { label: 'The Dark Knight', id: 4 },
        { label: '12 Angry Men', id: 5 },
        { label: "Schindler's List", id: 6 },
        { label: 'Pulp Fiction', id: 7 },
    ]
    return (
        <div>
            <Button onClick={handleOpen} sx={{ color: "#852065" }} className='border-noner text-xl font-medium'>
                Search by symptoms
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-col'>
                        <h4 className='text-sm font-medium text-primary_color'>
                            Search by Symptom
                        </h4>
                        <p className='text-lg  font-medium'>Tell us your symptoms and and we will help to find the Specialty</p>
                        <div className='flex w-full'>
                            <Autocomplete
                                className='mt-6 mb-5'

                                fullWidth={true}
                                options={top100Films}
                                onChange={(event, value) => {
                                    if (value == null) {
                                        return;
                                    }
                                    console.log(value.label)
                                    if (!selectedSymptoms.some(item => item.id === value.id)) {
                                        setSelectedSymptoms(prev => [...prev, value]);
                                    }
                                }}
                                size='small'
                                popupIcon={
                                    <div className='rounded-full h-[30px] w-[30px] flex items-center justify-center bg-primary_color'><FaSearch size={15} color='white' /></div>
                                }

                                sx={{
                                    [`& .${autocompleteClasses.popupIndicator}`]: {
                                        transform: "none"
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "50px",

                                        },
                                        "& .MuiAutocomplete-inputRoot": {

                                            borderRadius: "50px"
                                        },
                                        "& .MuiInputLabel-outlined": {

                                        },
                                        "& .MuiInputLabel-shrink": {
                                            background: "white"
                                        }
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    label="Search for Symptoms"
                                />}
                            />
                            <div className='w-28'>

                            </div>
                        </div>
                        {
                            selectedSymptoms.length > 0 && <div className='w-full mb-5 flex flex-wrap border-[0.5px] rounded-xl border-black bg-gray-200 p-3 gap-2'>
                                {
                                    selectedSymptoms.map((item, index) => (
                                        <SpecialityCard key={index} item={item} fn={() => {
                                            setSelectedSymptoms(prev => prev.filter(i => i.id !== item.id))
                                        }} />
                                    ))
                                }
                            </div>
                        }
                        <button className='border-none text-white w-fit text-base bg-primary_color px-4 py-1 rounded-lg'>
                            Get result
                        </button>
                        <div className='h-[1px] bg-gray-700 w-full my-5'></div>
                        <div className='flex flex-col items-center'>
                            <h2 className='font-medium text-lg'>Based on the symptoms u should visit: </h2>
                            <a className='text-sm text-primary_color' href="">Oncologist </a>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default SearchBySymptom

const SpecialityCard = ({ item, fn }) => {
    return (
        <div className='py-1 px-4 flex items-center gap-x-2 w-fit bg-gray-700  rounded-lg text-white'>
            <p>{item.label}</p>
            <IoMdClose className='cursor-pointer' onClick={fn} />
        </div>
    )
}