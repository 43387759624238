import React from "react";
import { FaUserDoctor } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";

const CallUsFloat = () => {
  return (
    // <a href="tel:0471 3100 100" className="fixed right-6 bottom-56 md:right-11  cursor-pointer z-40 hover:scale-105 transition duration-300 w-fit
    //     p-3 bg-primary_color text-white flex items-center
    //     justify-center rounded-full border-4 border-white">
    //     <FaPhoneAlt size={26} />
    // </a>

    <div className="fixed right-6 bottom-[155px] lg:bottom-60 md:right-11  cursor-pointer z-40 hover:scale-105 transition duration-300">
      <a
        href="tel:0471 3100 100"
        className="bg-gray-300 flex items-center justify-center rounded-full py-3 px-5 relative group" // Added 'group' class
      >
        {/* <span className="px-3 text-sm pt-1">Chat With Us </span> */}

        <div className="p-2 hidden group-hover:flex group-hover:ease-in-out group-hover:duration-300  bg-primary_color text-white items-center absolute text-nowrap right-12 self-center rounded-lg ">
          <span className="text-xs px-3">Call Now</span>
        </div>

        <div
          className="p-2.5 bg-primary_color text-white flex items-center absolute 
      align-start lg:-left-5 rounded-full border-4 border-white"
        >
          {/* <FaPhoneVolume /> */}
          <FaPhoneAlt  className="lg:text-2xl" />
        </div>
      </a>
    </div>
  );
};

export default CallUsFloat;
