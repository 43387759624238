import React from "react";
import "./auth.css";
import { Link } from "react-router-dom";

const Signup = () => {
  return (
    <div className="w-full h-screen bg-[#45273c] maindiv flex mt-32">
      <div
        className="hidden sm:w-1/2 h-screen 
      sm:flex flex-col items-center justify-center gap-5 relative"
      >
        <div className="w-[350px] h-[400px] bg-transparent chip1 relative">
          <img
            src={require("../../assets/logo/moving_logo.png")}
            className="w-[120px] h-[120px] absolute left-6 -top-6 opacity-30"
            alt="logo"
          />

          <img
            src={require("../../assets/logo/moving_logo.png")}
            className="w-[40px] h-[40px] absolute left-4 top-[120px] opacity-30"
            alt="logo"
          />
          <img
            src={require("../../assets/logo/moving_logo.png")}
            className="w-[80px] h-[80px] absolute left-3 top-[180px] opacity-30"
            alt="logo"
          />

          <img
            src={require("../../assets/logo/moving_logo.png")}
            className="w-[180px] h-[180px] absolute left-16 top-[240px] opacity-30"
            alt="logo"
          />
        </div>

        <div className="w-[350px] h-[400px] bg-transparent absolute chip2"></div>

        <div className="w-[350px] h-[300px] bg-transparent absolute chip3"></div>

        <div className="w-[260px] h-[400px] bg-transparent absolute imgdiv"></div>
      </div>

      <div
        className="w-full sm:w-1/2 h-screen bg-[#FCFEFF] sm:rounded-tl-xl sm:rounded-bl-xl
      flex flex-col items-center justify-center gap-5 relative overflow-clip z-40"
      >
        <h1 className="text-2xl font-bold">Create your Free Account</h1>

        <div className="w-[70%] h-auto flex flex-col items-start">
          <label className="text-[#7C838A]">Full Name</label>
          <input
            placeholder="Enter your full name here"
            className="bg-[#dcdedf] w-full h-[7vh] outline-none p-5 rounded-xl"
          />
        </div>

        <div className="w-[70%] h-auto flex flex-col items-start">
          <label className="text-[#7C838A]">Email</label>
          <input
            placeholder="Enter your email here"
            className="bg-[#dcdedf] w-full h-[7vh] outline-none p-5 rounded-xl"
          />
        </div>

        <div className="w-[70%] h-auto flex flex-col items-start">
          <label className="text-[#7C838A]">Password</label>
          <input
            placeholder="Enter your password here"
            className="bg-[#dcdedf] w-full h-[7vh] outline-none p-5 rounded-xl"
          />
        </div>

        <button className="w-[80%] lg:w-1/3 bg-primary_color text-white p-3 rounded-lg ">
          Create Account
        </button>

        <p className="text-[#7C838A] cursor-pointer">
          Already have an account?
          <Link to="/login" className="text-primary_color">
            Log in
          </Link>
        </p>

        {/* <span className='text-[#B0BAC3] text-lg'> - OR - </span> */}

        {/* bottom right */}
        <img
          src={require("../../assets/logo/moving_logo.png")}
          className="w-[200px] h-[200px] absolute -right-12 bottom-10 opacity-30"
          alt="logo"
        />

        {/* Top left */}
        <img
          src={require("../../assets/logo/moving_logo.png")}
          className="w-[160px] h-[160px] absolute -left-16 -top-6 opacity-30"
          alt="logo"
        />

        <img
          src={require("../../assets/logo/moving_logo.png")}
          className="w-[100px] h-[100px] absolute left-[130px] top-6 opacity-30"
          alt="logo"
        />

        <img
          src={require("../../assets/logo/moving_logo.png")}
          className="w-[60px] h-[60px] absolute left-0 top-[150px] opacity-30"
          alt="logo"
        />

        {/* Bottom left */}
        <img
          src={require("../../assets/logo/moving_logo.png")}
          className="w-[100px] h-[100px] absolute -left-10 bottom-[100px] opacity-30"
          alt="logo"
        />

        <img
          src={require("../../assets/logo/moving_logo.png")}
          className="w-[60px] h-[60px] absolute left-8 bottom-5 opacity-30"
          alt="logo"
        />
      </div>
    </div>
  );
};

export default Signup;
