import React, { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import "./chatbot.css";
import Message from "./Message";
import image from '../../../assets/logo/moving_logo.png'

const Chatbot = ({ setShowBot }) => {
  const [userInput, setUserInput] = useState("");

  const [messages, setMessages] = useState([
    { name: "bot", text: "How can i help you?" },
    // { name: 'user', text: "Hello i need to book an appoin ment" },
  ]);

  const sendMsg = async () => {
    if (userInput === "") return;

    setMessages((prev) => [...prev, { name: "user", text: userInput }]);

    // Send the userInput to backend and push the response into messages with name as 'bot'

    setUserInput("");
  };

  return (
    <div className="w-full sm:w-1/4 h-[75vh] bg-[#e0e0e0] fixed bottom-10 right-5 cursor-pointer  z-50 rounded-lg shadow-lg flex flex-col">
      <div className="bg-white w-full h-[8vh] flex justify-between items-center px-5 py-3  rounded-lg shadow-bottom border-none">
        <span className="text-primary_color text-lg font-semibold">
          Chat with us
        </span>
        <IoIosCloseCircle size={24} onClick={() => setShowBot(false)} className='text-primary_color' />
      </div>

      <div className=" bg-[#e0e0e0] w-full h-[67vh] relative flex flex-col items-center">
        <img src={image} alt="logo" className="absolute opacity-20 w-full" />
        <div className="w-full mb-2 h-full flex flex-col p-7 overflow-y-scroll">
          {messages?.map((msg) => (
            <Message msg={msg} />
          ))}
        </div>

        <div className="w-[90%] h-auto min-h-[7vh] px-3 bg-white mb-5 rounded-lg  shadow-lg flex gap-2 items-center ">
          <textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="flex-1 text-sm h-auto justify-center overflow-y-scroll py-1 border-none outline-none"
          />

          <div onClick={sendMsg} className="w-fit h-fit flex-none">
            <IoMdSend size={24} color="#852065" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
