import React from 'react'
import DoctorCard from './DoctorCard'

function SpecializedGrid() {
    return (
        <div className='flex flex-col w-full mt-8 items-start'>
            <div className='flex items-center w-full'>
                <div className='w-full h-1 bg-gray-300'></div>
                <h4 className='text-paragraph text-gray-500 font-medium pl-3'>Orthopaedic</h4>
            </div>
            <div className='grid w-full gap-5 grid-cols-3 mt-5'>
                <DoctorCard />
                <DoctorCard />
                <DoctorCard />
                <DoctorCard />
                <DoctorCard />
            </div>
        </div>
    )
}

export default SpecializedGrid
