import React from "react";

function Piller(props) {
  let outerContainerStyle, anime;
  if (props.no % 2 === 0) {
    anime = "fade-left";
    outerContainerStyle =
      "relative w-full max-md:w-10/12 flex  flex-row-reverse max-md:flex-col mb-9 h-fit";
  } else {
    anime = "fade-right";
    outerContainerStyle =
      "relative w-full max-md:w-10/12 flex max-md:flex-col mb-9 h-fit";
  }
  let numberStyle;
  if (props.no % 2 === 0) {
    numberStyle =
      "absolute right-0 max-md:right-auto w-[120px] max-md:w-[60px]  h-[120px] max-md:h-[60px] flex items-center justify-center rounded-full bg-primary_color max-md:-ml-[20px] max-md:-mt-[20px]";
  } else {
    numberStyle =
      "absolute w-[120px] h-[120px] max-md:w-[60px] max-md:h-[60px] flex items-center justify-center rounded-full bg-primary_color max-md:-ml-[20px] max-md:-mt-[20px]";
  }
  let imageStyle;
  if (props.no % 2 === 0) {
    imageStyle = "rounded-2xl  mr-[50px] mt-[50px] w-[60%] max-md:hidden";
  } else {
    imageStyle = "rounded-2xl  ml-[50px] mt-[50px] w-[60%] max-md:hidden";
  }
  let imageStyleMobile;
  if (props.no % 2 === 0) {
    imageStyleMobile = "rounded-t-2xl md:hidden w-full";
  } else {
    imageStyleMobile = "rounded-t-2xl md:hidden w-full";
  }

  let textContainerStyle;
  if (props.no % 2 === 0) {
    textContainerStyle =
      "absolute max-md:relative bottom-[50px] max-md:bottom-0  left-0 w-[60%] max-md:w-full h-fit flex flex-col items-start p-8 max-md:p-7 bg-white rounded-xl shadow-[-21px_21px_70px_0px_rgba(0,0,0,0.3)] gap-y-3";
  } else {
    textContainerStyle =
      "absolute max-md:relative bottom-[50px] max-md:bottom-0 right-0 w-[60%] max-md:w-full h-fit flex flex-col items-start p-8 max-md:p-7 bg-white rounded-xl shadow-[-21px_21px_70px_0px_rgba(0,0,0,0.3)] gap-y-3";
  }
  return (
    <div
      data-aos={anime}
      data-aos-duration={1000 * Number(props.no)}
      className={outerContainerStyle}
    >
      <div className={numberStyle}>
        <h2 className="text-white text-5xl max-md:text-4xl font-medium">
          {props.no}
        </h2>
      </div>
      <img
        className={imageStyleMobile}
        src={require(`../../assets/about-us/${props.image}`)}
        alt=""
      />
      <div className={textContainerStyle}>
        <h1 className="text-2xl text-primary_color max-md:text-xl max-md:text-left">
          <span className="font-semibold">{props.title}</span> {props.subTitle}
        </h1>
        <p className="text-left text-lg max-md:text-base font-light ">
          {props.description}
        </p>
      </div>
      <img
        className={imageStyle}
        src={require(`../../assets/about-us/${props.image}`)}
        alt=""
      />
    </div>
  );
}

export default Piller;
