import React from 'react';
import banner from '../assets/coremanagement/banner.png';
import trophy from '../assets/coremanagement/trophy.png';
import award from '../assets/coremanagement/award.png';
import star from '../assets/coremanagement/star.png';
import logo1 from '../assets/coremanagement/logo1.png';
import logo2 from '../assets/coremanagement/logo2.png';
import logo3 from '../assets/coremanagement/logo3.png';

const CoreManagement = () => {
    return (
        <div className='w-full flex flex-col items-center'>
            <div className="mt-24 w-full py-16 px-5 gap-5 text-white
            bg-no-repeat bg-cover bg-center flex flex-col sm:flex-row justify-around items-center" style={{
                    backgroundImage: `url(${banner})`,
                }}
            >
                <div className="w-full sm:w-[50vw] h-[65vh] max-md:h-[60vh] flex flex-col justify-center 
            items-start bg-opacity-40 gap-y-4" >
                    <h4 className='text-md md:text-xl font-normal '>SP Medifort</h4>
                    <h1 className='text-3xl md:text-5xl font-medium  text-left'>Core Management</h1>
                    <div className='flex gap-4 text-lg font-light'>
                        <span>Mission</span>
                        <span>Vision</span>
                        <span>Achievements</span>
                        <span>Awards</span>
                    </div>
                </div>

                <img
                    src={trophy}
                    alt='trophy'
                    className='sm:w-[20vw] h-[25vh] sm:h-[40vh] object-contain'
                />

            </div>

            {/* What to expect */}
            <div className='w-full sm:w-[80vw] mt-8 p-5 gap-5 flex flex-col'>
                <h2 className='text-3xl text-left font-medium'>What to expect</h2>
                <hr className='h-[2px] bg-primary_color' />

                <div className='w-full flex flex-col sm:flex-row gap-4'>
                    <div className='w-full sm:w-[40%]  border border-[#6D6E71] rounded-lg
                    flex flex-col items-start p-5 gap-4'>
                        <h2 className='text-primary_color text-2xl font-medium'>Vision</h2>
                        <p className='text-lg text-left'>
                            To provide affordable health services at par with the best in the
                            world by continually updating our own standards in healthcare delivery.
                        </p>
                    </div>

                    <div className='w-full sm:w-[60%]  border border-[#6D6E71] rounded-lg
                    flex flex-col items-start p-5 gap-4'>
                        <h2 className='text-primary_color text-2xl font-medium'>Mission</h2>
                        <p className='text-lg text-left'>
                            To make the Hospital a frontline medical citadel by developing a comprehensive
                            healthcare delivery system, accessible to clientele all over the world and
                            maintaining competitive edge through delivery of quality care and evidence
                            based practice.
                        </p>
                    </div>


                </div>

            </div>

            {/* Awards */}
            <div className='w-full sm:w-[80vw] mt-8 p-5 gap-5 flex flex-col'>
                <h2 className='text-3xl text-left font-medium'>Awards</h2>
                <hr className='h-[2px] bg-primary_color' />

                <div className='w-full flex flex-col sm:flex-row flex-wrap gap-4 justify-center items-center'>
                    {
                        [1, 2, 3, 4, 5, 6].map((item, i) => (
                            <div key={i}
                                className='min-w-[22vw] w-full sm:w-fit py-8 px-5 h-[48vh] border border-[#6D6E71] rounded-lg
                                flex items-center justify-center relative'
                            >
                                <img src={award} alt='award'
                                    className='min-w-[18vw] w-full sm:w-fit h-[40vh] object-cover rounded-xl'
                                />

                                <div className='sm:w-[10vw] bg-white text-primary_color rounded-lg
                                absolute bottom-9 left-9 p-1'>
                                    <p className='text-xs'>
                                        New Age Entrepreneur of the North 2014 - HT
                                    </p>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>

            {/* Awards 2 */}
            <div className='w-full sm:w-[80vw] mt-8 p-5 gap-5 flex flex-col'>
                <h2 className='text-3xl text-left font-medium'>Awards</h2>
                <hr className='h-[2px] bg-primary_color' />

                {
                    [1, 2, 3, 4].map((item, i) => (
                        <div key={i}
                            className='w-full flex gap-4 items-start'
                        >
                            <img src={star} alt='bulletin'
                                className='w-[24px] h-[24px]'
                            />

                            <p className='text-left text-lg'>THE TIMES OF INDIA - Health Pioneers of North India 2013</p>

                        </div>
                    ))
                }
            </div>


            {/* Accolades */}
            <div className='w-full sm:w-[80vw] sm:h-[40vh] mt-12 p-5 border border-[#6D6E71] rounded-xl
            flex flex-col justify-evenly gap-6'>
                <h2 className='text-3xl text-center font-medium'>Accolades and Achievements</h2>

                <div className='flex flex-col sm:flex-row justify-evenly gap-6'>

                    {
                        [1,2,3].map((item, i) => (
                            <div key={i} className='w-full sm:w-[20vw] h-[20vh] border border-[#6D6E71] rounded-xl
                            flex flex-col items-center justify-evenly'>
                                <img src={logo1} alt='logo' 
                                className='w-[100px] h-[80px] object-contain'
                                />

                                <p>NABH Accreditation Since 2015</p>
                            </div>
                        ))
                    }
                </div>

            </div>

        </div>
    )
}

export default CoreManagement