import React from 'react'
import { Link } from 'react-router-dom'

function Card({
    name, location, phone, mapLink, image
}) {
    const number = `tel:${phone}`
    return (
        <a target='blank' href={mapLink} className=' flex flex-col items-start'>
            <img className=' aspect-video w-full object-contain border p-4 shadow-lg rounded-2xl' src={image} alt="" />
            <div className='flex flex-col items-start px-3 mt-4'>
                <h1 className='text-lg font-medium '>{name}</h1>
                <h2 className='text-normal font-light leading-6'>{location}</h2>
                {/* <div className='flex mt-2 gap-x-4'>
                    <a href={mapLink}
                        className='flex items-center text-primary_color gap-x-1' >
                        <FaLocationArrow />
                        <p>View in map</p>

                    </a>
                    <a href={number} className='flex items-center gap-x-1'>
                        <IoCall />
                        <p>{phone}</p>
                    </a >
                </div> */}
            </div>
        </a>
    )
}
export default Card