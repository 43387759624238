import React, { useState } from "react";
import { FaPhone, FaPhoneAlt } from "react-icons/fa";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const SubmitHandler = (e) => {
    e.preventDefault();
    setEmail("");
  };
  return (
    <div className="basis-1/4 flex flex-col items-start gap-4">
      {/* <h5 className="font-light">Get A Lot Of Information About Us</h5>
      <h1 className="font-bold text-xl">Subscribe Our Newsletter</h1>
      <form onSubmit={SubmitHandler} action="" className="flex flex-col gap-5">
        <input
          placeholder="Enter your email id"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-[320px] rounded-md p-[10px] text-black/60"
        />
        <button className="bg-primary_color w-[320px] rounded-md p-[10px] border border-white">
          Submit
        </button>
      </form> */}

      <div className="flex flex-col mt-3">
        <div className="mb-3">
          <h1 className="font-bold text-xl text-start">Download our app</h1>
        </div>
        <div className=" flex">
          <a
            target="blank"
            href="https://play.google.com/store/apps/details?id=com.spmedifort"
          >
            <img
              src={require("../../assets/footer/1.png")}
              alt="mobileAppLink"
            />
          </a>
          <a
            target="blank"
            href="https://apps.apple.com/in/app/sp-medifort/id6476657927"
          >
            <img
              src={require("../../assets/footer/2.png")}
              alt="mobileAppLink"
            />
          </a>
        </div>
        <a href="tel:+91 0471 3100 100">
          <div className="flex flex-col items-start relative px-[25px] mt-3">
            <span className="font-light text-sm">Health Help Line</span>
            <span>0471 3100 100</span>
            <span className="text-primary_color absolute top-3 left-0">
              <FaPhoneAlt size={19} className="rotate-45"/>
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Newsletter;
