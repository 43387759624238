import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { toast, toaster } from "react-toastify";

const Form = () => {
    const { t } = useTranslation();

    const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  
    useEffect(() => {
      emailjs.init(PublicKey);
    }, []);
  
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "Message From Insurance",
      message: "",
    });
  
    const [loading, setLoading] = useState(false);
  
    const [successMsg, setSuccessMsg] = useState(null);
  
    const [errors, setErrors] = useState({});
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    const validateForm = () => {
      const newErrors = {};
  
      if (!formData.name.trim()) {
        newErrors.name = "Name is required";
      }
  
      if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
        newErrors.email = "Please enter valid email";
      }
  
      if (!formData.phone.trim()) {
        newErrors.phone = "Phone number is required";
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      console.log(formData);
      if (!validateForm()) return;
  
      setLoading(true);
  
      emailjs
        .sendForm(ServiceId, TemplateId, e.target, PublicKey)
        .then((result) => {
          console.log("Email sent successfully!");
  
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
  
          toast.success("Message Send Successfully");
          setLoading(false);
  
          console.log("Form submitted:", result);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          toast.error("Please try after sometime");
          setErrors((prev) => ({ ...prev, server: "Something went wrong" }));
          setLoading(false);
        });
    };
  return (
    <div className="p-3">
      <div className="max-w-common mx-auto p-5 border border-primary_color rounded-xl flex flex-col w-full md:w-1/2 gap-3 items-center text-primary_color">
        <h1 className="text-primary_color font-bold text-2xl md:text-headings font-sans ">
          {t("contact")}
        </h1>

        <form
          action=""
          onSubmit={handleSubmit}
          className="flex flex-col  w-full gap-5 mt-5 "
        >
          <input
            placeholder="Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            required
            className="w-full  bg-transparent border-b border-[#c9c9c9] outline-none text-primary_color"
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          <input
            placeholder="Email ID"
            name="email"
            value={formData?.email}
            onChange={handleChange}
            required
            className="w-full  bg-transparent border-b border-[#c9c9c9] outline-none"
          />
          {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
          <input
            placeholder="Phone Number"
            name="phone"
            value={formData?.phone}
            onChange={handleChange}
            required
            className="w-full  bg-transparent border-b border-[#c9c9c9] outline-none"
          />
          {errors.phone && (
            <span style={{ color: "red" }}>{errors.message}</span>
          )}
          <textarea
            name="message"
            id=""
            value={formData?.message}
            onChange={handleChange}
            placeholder="Your message"
            className="w-full  rounded-lg h-20 p-5 bg-transparent  border-2 focus:outline-none"
          ></textarea>
          {errors.message && (
            <span style={{ color: "white" }}>{errors.message}</span>
          )}
          <button className="w-[100px] bg-primary_color text-white p-3 mt-5 rounded-3xl ">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
