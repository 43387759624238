import React from "react";
import TestCard from "./TestCard";
import { tests } from "../../data/tests";

const Test = () => {
  return (
    <div className="w-full flex flex-wrap gap-5 items-start">
      {tests?.map((item, i) => (
        <TestCard
          key={i}
          name={item?.name}
          price={item?.price}
          list={item?.list}
        />
      ))}
    </div>
  );
};

export default Test;
