import React from "react";
import FeaturesCard from "./FeaturesCard";
import { useTranslation } from "react-i18next";

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const words = t("whyChooseUs.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  const featuresData = [
    {
      title: "Facilities",
      description:
        "Fully Robotic (Zeiss Kinevo) Neuro-microscope system in South Kerala.",
      icon: require("../../../assets/whychoose/doctor_icon.png"),
    },
    {
      title: "State-of-the-Art Facilities",
      description:
        "3D Hybrid Cath Lab (GE Allia) with Artificial Intelligence, in South Asia",
      icon: require("../../../assets/whychoose/facilities.png"),
    },
    {
      title: "Massive Covered Car Parking",
      description: "Covered Multi-level 650-car parking in South Kerala.",
      icon: require("../../../assets/whychoose/world_care.png"),
    },
    {
      title: "Patient-Centric Approach",
      description:
        "Patient-friendly fully motorized X-ray suite in South Kerala. 24x7 remote patient monitoring for clinical support, in South Kerala.",
      icon: require("../../../assets/whychoose/old_man.png"),
    },
  ];
  return (
    <div className="mt-32 max-md:mt-14 flex flex-col items-center max-md:mb-20 font-newFont">
      <h1 className="font-bold text-2xl md:text-headings font-sans capitalize ">
        {firstPart}
        <span className="text-primary_color"> {secondPart} </span>
      </h1>
      <h6 className="md:text-xl font-light mt-2 p-2">
        {t("whyChooseUs.subTitle")}
      </h6>
      <div className="mt-3 max-md:mt-10 h-[1300px] max-md:h-fit flex flex-col items-center justify-end">
        <div className=" grid grid-cols-2 gap-x-20 gap-y-32 max-md:gap-8 mb-24 max-md:mb-6  max-md:grid-cols-1 max-md:justify-center ">
          <div className="mt-20 max-md:mt-0 max-md:w-fit">
            <FeaturesCard
              title={featuresData[0].title}
              description={featuresData[0].description}
              icon={featuresData[0].icon}
            />
          </div>
          <FeaturesCard
            title={featuresData[1].title}
            description={featuresData[1].description}
            icon={featuresData[1].icon}
          />
        </div>
        <div className=" grid grid-cols-2 gap-x-64 mb-[500px] max-md:mb-0 gap-y-32 max-md:gap-8 max-md:grid-cols-1 ">
          <div className="mt-20 max-md:mt-0 max-md:w-fit">
            <FeaturesCard
              title={featuresData[2].title}
              description={featuresData[2].description}
              icon={featuresData[2].icon}
            />
          </div>
          <FeaturesCard
            title={featuresData[3].title}
            description={featuresData[3].description}
            icon={featuresData[3].icon}
          />
        </div>
        <img
          className="h-[60rem] absolute max-md:hidden"
          src={require("../../../assets/whychoose/choose_us_doctor.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;
