import React from 'react'

function FeaturesCard(props) {
    return (
        <div data-aos="fade-up" data-aos-duration="800" className='w-[450px] max-md:w-11/12 p-9 shadow-[21px_21px_70px_0px_rgba(0,0,0,0.3)] max-md:shadow-[5px_5px_40px_0px_rgba(0,0,0,0.3)] rounded-lg h-fit max-md:self-center max-md:justify-self-max-md:center max-md:mx-auto'>
            <img className='h-[55px]' src={props.icon} alt="" />
            <h2 className='text-2xl font-bold text-primary_color text-left'>
                {props.title}
            </h2>
            <p className='md:text-lg font-light pt-2 text-left'>
                <span className='text-2xl font-extrabold text-primary_color'>1</span><sup className='text-primary_color font-bold text-lg'>st</sup> {props.description}
            </p>
        </div>
    )
}

export default FeaturesCard
