import React, { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function Dropdown({ title, children, mobview, dropArr, chooseOne }) {
  const { t } = useTranslation();
  const location = useLocation();

  // const [isOpen, setIsOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // useEffect(() => {
  //   setIsOpen(false);
  // }, [location]);

  const dropObj = dropArr.find(el=> el?.title === title);

  const isOpen = dropObj?.value

  return (
    <>
      <div className="relative  hidden md:inline-block">
        <span
          onClick={()=> chooseOne(title, dropArr)}
          className="flex items-center gap-2 hover:cursor-pointer"
        >
          {t(`navbar.${title}`)}
          {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}{" "}
        </span>

        {isOpen && (
          <ul
            className={`absolute z-50 top-10  w-fit ${
              mobview
                ? "bg-white min-w-[40vw] -left-10"
                : "bg-primary_color min-w-[10vw] -left-5"
            } shadow-md rounded-b-md rounded-t-none overflow-hidden mt-1`}
          >
            {children}
          </ul>
        )}
      </div>
      <div className="relative flex w-full items-center justify-center sm:hidden">
        <span
          onClick={()=> chooseOne(title, dropArr)}
          className="flex items-center gap-2 hover:cursor-pointer"
        >
          {t(`navbar.${title}`)}
          {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}{" "}
        </span>

        {isOpen && (
          <ul
            className={`absolute z-50 top-7  w-fit ${
              mobview
                ? "bg-white w-full "
                : "bg-primary_color min-w-[10vw] -left-5"
            } shadow-md rounded-b-md rounded-t-none overflow-hidden mt-1`}
          >
            {children}
          </ul>
        )}
      </div>
    </>
  );
}

export default Dropdown;
