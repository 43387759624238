import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import StatsText from "./StatsText";
import Leader from "../../leaderShip/Leader";
import "./AboutUs.css";

const AboutUs = ({ id }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const { t } = useTranslation();

  const [quickLinkScrollPostion, setQuickLinkScrollPostion] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const videoRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const quickLinkscroll = document.getElementById("quick-link");
      if (!quickLinkscroll) {
        setQuickLinkScrollPostion(quickLinkscroll);
      }
      // console.log("Quick Link Scroll Position:", quickLinkscroll);
      var aboutscroll = window.scrollY - quickLinkscroll;
      setIsScrolling(true);
      if (aboutscroll > 0) {
        // console.log("Scroll Position:sdsd", scrollPosition);
        setScrollPosition(aboutscroll);
      }
      if (aboutscroll === 0) {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition, quickLinkScrollPostion]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef]);

  // const handleFullScreen = (value) => {
  //   setIsFullScreen(value);
  // };

  const playerWidth = `calc(60vw + ${scrollPosition}px-500px)`;
  const playerHeight = `calc(50vh + ${scrollPosition}px-900px)`;
  const playerPosition = isScrolling ? "relative" : "absolute";
  const playerTopPadding = isScrolling ? "-05vh" : 0;
  const playerStyle = {
    width: playerWidth,
    height: playerHeight,
    position: playerPosition,
    maxHeight: "100vh",
    marginTop: playerTopPadding,
    maxWidth: "70%",
    zIndex: 0,
    borderRadius: "0  3rem 0 0",
  };
  const aboutUsCardStyle = {
    right: -scrollPosition,
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
  };

  return (
    <div id={id} className="flex flex-col items-center relative">
      <div className="max-md:flex max-md:flex-col mt-5 md:my-32 lg:mb-14 about-window w-full h-screen max-md:h-fit ">
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          className="md:w-[53vw] md:h-[64vh] object-contain w-screen rounded-r-[4rem] max-md:rounded-tr-none "
        >
          <source
            // src="https://firebasestorage.googleapis.com/v0/b/juza-foods.appspot.com/o/sp%20medifort%20square%20video%201.mp4?alt=media&token=9b057efc-7925-4309-8466-3a5114b27dd9"
            src="https://firebasestorage.googleapis.com/v0/b/test-230be.appspot.com/o/WhatsApp%20Video%202024-07-31%20at%2013.30.01_b9d88c5e.mp4?alt=media&token=dce48040-f02e-47f8-b08f-4e9c99c03754"
            type="video/mp4"
          />
        </video>

        {/* about us card */}
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="absolute max-md:relative  right-0 top-[1rem] max-md:top-0 max-md:mt-6 w-[45%] h-full max-md:w-11/12 max-md:mx-auto 
         rounded-bl-3xl rounded-tl-3xl max-md:rounded-none  max-md:shadow-none p-[3.5rem] max-md:p-2 pt-6 text-sm tracking-wide font-newFont text-left"
        >
          {/* Founder image */}
          <div
            className="mb-5 md:mb-10 md:ps-44"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={require("../../../assets/about-us/founder.png")}
              alt="founder"
              className="w-40 md:w-52"
            />
          </div>

          <h1 className="text-4xl font-bold text-gray-800  font-sans">
            {t("about").split(" ")[0]}{" "}
            <span style={{ color: "#852065" }}>{t("about").split(" ")[1]}</span>
          </h1>
          <p className="text-600 mt-4 ">{t("aboutus.para1")}</p>

          <p className="mt-1">{t("aboutus.listhead")}</p>
          <ul className="list-disc ps-6 py-2  ">
            <li>{t("aboutus.list1")}</li>
            <li>{t("aboutus.list2")}</li>
            <li>{t("aboutus.list3")}</li>
            <li>{t("aboutus.list4")}</li>
          </ul>

          <p className="text-600 mt-4">{t("aboutus.para2")}</p>

          <Link to={"/about-us"}>
            <button className="my-5 border-2  border-primary_color p-2 px-5 rounded text-primary_color  hover:bg-primary_color hover:text-white hover:font-normal">
              See more
            </button>
          </Link>
        </div>

        {/* search box */}
        {/* <div
          data-aos="fade-up"
          data-aos-duration="300"
          className="rounded-xl z-20  w-[70vw] md:w-[50vw] absolute  bottom-[35%] left-[10%] 
          mx-auto max-md:right-0 max-md:mx-auto bg-[#F8F8F8] shadow-[0_6px_60px_-15px_rgba(0,0,0,0.3)] p-[3rem] max-md:p-9"
        >
          <div>
            <form
              action=""
              className="flex  max-md:flex-col gap-x-10 max-md:gap-y-4 justify-between pb-6"
            >
              <SearchBox placeholder="search by doctors" />
              <SearchBox placeholder="search by department" />
              <button className="py-3 px-9 bg-[#852065] text-white rounded-md">
                <FaSearch size={20} />
              </button>
            </form>
          </div>
          <h1 className="text-base font-bold text-left">
            You may be looking for
          </h1>
          <div className="flex  w-full pt-6 overflow-x-scroll hide-scrollbar">
            <div className="w-full gap-4 flex mr-9 ">
              <FilterCard text="Dental Care" />
              <FilterCard text="Medicine Specialist" />
              <FilterCard text="Eye Specialist" />
              <FilterCard text="Dental Care" />
              <FilterCard text="Medicine Specialist" />
              <FilterCard text="Medicine Specialist" />
              <FilterCard text="Medicine Specialist" />
              <FilterCard text="Medicine Specialist" />
            </div>
          </div>
        </div> */}

        {/* moving logo */}
        <div className="absolute right-0 top-64  moving-logo2 opacity-40 bottom-6 -z-10 max-md:hidden">
          <img
            className="object-contain h-[150px] animate-spin  "
            style={{ animationDuration: "60s" }}
            src={require("../../../assets/logo/moving_logo.png")}
            alt=""
          />
        </div>
      </div>

      <div className="w-full md:mb-24">
        <Leader />
      </div>

      {/* Statistics - Hidden */}
      {/* <div className="flex max-md:grid max-md:grid-cols-2 w-[70vw] max-md:w-11/12  max-md:ml-0 mb-20 h-[150px] max-md:h-fit max-md:gap-y-6 max-md:py-10  center rounded-xl bg-gradient-to-r from-[#852065] to-[#C17FAC] items-center justify-evenly">
        <StatsText
          value="25"
          symbol="+"
          subTitle={t("landing.yearOfExperience")}
        />
        <StatsText
          value="95"
          symbol="%"
          subTitle={t("landing.patientSatisfactionRate")}
        />
        <StatsText
          value="1000"
          symbol="+"
          subTitle={t("landing.patientsServedAnnually")}
        />
        <StatsText
          value="10"
          symbol="+"
          subTitle={t("landing.healthcareProvidersOnStaff")}
        />
        <StatsText
          value="5000"
          symbol="+"
          subTitle={t("landing.happyIPpatients")}
        />
      </div> */}

    </div>
  );
};

export default AboutUs;
