import React from 'react'

const Brochure = ({data}) => {
  return (
    <div className='w-full sm:w-[18vw] h-[40vh] bg-[#F6F7F9] 
    p-3 flex flex-col items-center gap-2' >
        <img 
        className='w-full h-[25vh] object-cover'
        src={require('../../assets/corporate/nurse.png')} />

        <div className='w-full flex flex-col items-start'>
            <span className='text-primary_color text-xs'>{data?.date}</span>
            <span className='text-primary_color font-bold'>{data?.title}</span>
            <div className='text-primary_color text-xs mt-3 flex gap-3'>
                <span className='font-semibold cursor-pointer'>Download</span>
                <span className='cursor-pointer'>Read more</span>
            </div>
        </div>

    </div>
  )
}

export default Brochure