import React from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { IoMdImage } from "react-icons/io";
import { BiSolidFilePdf } from "react-icons/bi";
import { BsFileEarmarkBreakFill } from "react-icons/bs";

const Report = () => {
  const summary = [
    {
      id: 1,
      title: `Doctor`,
      subTitle: `Dr. Smitha Dharan`,
      desc: `Cardiology`,
    },
    {
      id: 2,
      title: `Diagnoses`,
      subTitle: `Closed fracture of shaft of left clavicle`,
      desc: `S42.022D Displaced fracture of shaft of left clavicle, subsequent
        encounter for fracture with routine
         healing`,
    },
    {
      id: 3,
      title: `Radiology`,
      subTitle: `X ray shoulder complete, min 2 views`,
      desc: `S42.022D Displaced fracture of shaft of left clavicle, subsequent
        encounter for fracture with routine 
        healing`,
    },
  ];

  return (
    <div className="w-full h-auto mt-[12rem] flex justify-center items-center px-1 sm:px-10">
      <div className="w-full h-auto bg-white shadow-lg rounded-xl p-3 sm:p-10 flex flex-col items-center gap-4 ">
        <div className="w-full flex flex-col sm:flex-row justify-between items-center gap-3 px-1 sm:px-4">
          <h2 className="text-primary_color text-2xl font-bold">
            Patient Report
          </h2>

          <div className="flex flex-col sm:flex-row items-center gap-3">
            <input
              type="date"
              className="border border-primary_color p-2 text-primary_color rounded-lg"
            />
            <button className="w-full lg:w-fit bg-primary_color text-white p-3 rounded-lg">
              Download Now
            </button>
          </div>
        </div>

        <div className="w-full flex flex-col sm:flex-row p-1 sm:p-4">
          <div className="w-full sm:w-[40%] h-auto gap-6 flex flex-col p-5 ">
            <div
              className="w-full h-[44vh] rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-col p-6 gap-4"
            >
              <span className="absolute top-5 right-5 cursor-pointer">
                <BsThreeDots size={24} color="#D9D9D9" />
              </span>

              <div className="flex items-center gap-4">
                <img
                  src={require("../../assets/patient/patient.jpeg")}
                  className="w-[70px] h-[70px] rounded-full object-cover"
                  alt="patient"
                />

                <span className="font-bold">Mr. Manu M</span>
              </div>

              <div className="flex flex-col items-start text-left gap-3">
                <h1 className="font-bold">Contact Details:</h1>
                <p className="flex flex-wrap items-center gap-2">
                  <FaPhone color="#852065" size={16} /> 987654321
                </p>
                <p className="flex flex-wrap  items-center gap-2">
                  <IoMail color="#852065" size={16} /> test@gmail.com
                </p>
                <p className="flex flex-wrap  items-center gap-2">
                  <FaHome color="#852065" size={16} /> test villa, test street,
                  textplace P.O
                </p>
              </div>
            </div>

            <div
              className="w-full h-auto rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-col p-4 sm:p-6 gap-4"
            >
              <span className="absolute top-5 right-5 cursor-pointer">
                <BsThreeDots size={24} color="#D9D9D9" />
              </span>

              <div className="flex flex-col items-start text-left gap-3">
                <h1 className="font-bold">Overview:</h1>
                <div className="w-full flex flex-col ">
                  <div className="w-full flex flex-wrap items-center justify-between">
                    <div className="lg:w-1/3 flex flex-col">
                      <span className="text-[#C2C2C2]">Gender:</span>
                      <span className="font-bold">Male</span>
                    </div>

                    <div className="lg:w-1/3 flex flex-col">
                      <span className="text-[#C2C2C2]">Date of Birth:</span>
                      <span className="font-bold">22/06/1993</span>
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between">
                    <div className="lg:w-1/3 flex flex-col">
                      <span className="text-[#C2C2C2]">Next of Kin:</span>
                      <span className="font-bold">Manoj M</span>
                    </div>

                    <div className="lg:w-1/3 flex flex-col">
                      <span className="text-[#C2C2C2]">Previous visit:</span>
                      <span className="font-bold">22/06/1993</span>
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between">
                    <div className="lg:w-1/3 flex flex-col">
                      <span className="text-[#C2C2C2]">Next visit:</span>
                      <span className="font-bold">22/06/1993</span>
                    </div>
                    <div className="lg:w-1/3 flex flex-col">
                      <span className="text-[#C2C2C2]">Allergies:</span>
                      <span className="font-bold">None</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-[60%] h-auto gap-6 flex flex-col p-5 ">
            <div
              className="w-full h-auto rounded-3xl border border-primary_color shadow-xl relative
                    flex flex-col pt-[10vh]"
            >
              <div
                className="w-full h-[10vh] bg-primary_color text-white absolute top-0 left-0 
                            rounded-tr-3xl rounded-tl-3xl flex items-center justify-center"
              >
                <p>Encounter Summary for 12/03/23</p>
              </div>

              <div className="w-full h-auto p-6 flex flex-col gap-3">
                {summary?.map((item) => (
                  <div
                    key={item.id}
                    className="flex flex-col items-start gap-2"
                  >
                    <h3 className="text-primary_color underline">
                      {item.title}
                    </h3>
                    <span className="font-semibold">{item.subTitle}</span>
                    <p className="text-left">{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
