import React, { useEffect, useRef, useState } from 'react'

function StatsText(props) {
    const [expCount, setExpCount] = useState(15);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        startCount();
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0,
            }
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);
    const startCount = () => {
        const targetCount = props.value;
        const duration = 200; 
        const increment = (targetCount / 2) / duration;
        let currentCount = props.value * 3 / 4;

        const interval = setInterval(() => {
            currentCount += increment;
            setExpCount(Math.floor(currentCount));

            if (currentCount >= targetCount) {
                clearInterval(interval);
            }
        }, 10);

        return () => clearInterval(interval);
    };
    return (
        <div ref={elementRef} className='flex flex-col w-auto items-center'>
            <div className='flex text-5xl font-bold text-white'> <h1>{expCount}</h1> <h1>{props.symbol}</h1></div>
            <div className='text-white text-xs mt-3'>{props.subTitle}</div>
        </div>
    )
}

export default StatsText
