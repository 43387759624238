import React from 'react'
function OpenPositionCard(props) {
    return (
        <div className='flex flex-col p-10 max-md:p-6 w-full md:w-[500px] self-center  justify-self-center rounded-2xl items-start max-md:items-center border-b-[7px] border-primary_color'>
            <div className='flex w-full justify-between mb-6 max-md:mb-3 max-md:justify-center'>
                <h1 className='text-4xl font-bold max-md:text-center'>Unit Manager</h1>
                <button className=' p-3 rounded-lg text-sm font-medium shadow-[-12px_12px_60px_0px_rgba(0,0,0,0.2)] max-md:hidden'>Apply Now</button>
            </div>
            <h4 className='font-medium max-md:mb-2'>MSc/Bsc/Post Bsc/GNM with 10 year Above </h4>
            <p className='opacity-70 text-left'>To Manage the Nursing Unit To manage the Head Nurses To have a strong nursing administration knowledge.</p>
            <button className=' p-3 rounded-lg text-sm font-medium shadow-[-12px_12px_60px_0px_rgba(0,0,0,0.2)] max-md:mt-4 md:hidden'>Apply Now</button>
        </div>
    )
}

export default OpenPositionCard
