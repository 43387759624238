import React from 'react'

function TopBanner(
    {
        image, title, subTitle
    }
) {
    return (
        <div className="mt-28 w-full bg-no-repeat bg-cover bg-center" style={{
            backgroundImage: `url(${image})`,
        }}
        >
            <div className="h-[65vh] max-md:h-[60vh] flex flex-col justify-center items-center bg-primary_color contrast-125 bg-opacity-40 backdrop-saturate-0 gap-y-4" >
                <h1 className='text-6xl max-md:text-5xl font-medium text-white'>{title}</h1>
                <h3 className='text-4xl max-md:text-2xl font-extralight max-md:font-light text-white'>{subTitle}</h3>
            </div>
        </div>
    )
}

export default TopBanner
