import { Autocomplete, TextField, autocompleteClasses } from '@mui/material'
import React from 'react'
import { FaSearch } from "react-icons/fa";
function SearchDoctorsAndSpecialities() {
    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },
    ]
    return (
        <div className='w-full'>
            <Autocomplete
                disablePortal
                fullWidth={true}
                options={top100Films}
                sx={{
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                        transform: "none"
                    }
                }}
                popupIcon={
                    <div className='rounded-full h-[40px] w-[40px] flex items-center justify-center bg-primary_color'><FaSearch size={20} color='white' /></div>
                }
                renderInput={(params) => <TextField
                    {...params}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",

                        },
                        "& .MuiAutocomplete-inputRoot": {

                            borderRadius: "50px"
                        },
                        "& .MuiInputLabel-outlined": {

                        },
                        "& .MuiInputLabel-shrink": {
                            background: "white"
                        }
                    }}
                    label="Search for doctors and specialities"
                />}
            />
        </div>
    )
}

export default SearchDoctorsAndSpecialities
