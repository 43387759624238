import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enresources from '../locales/en.json'; 
import arResources from '../locales/ar.json'; 
import taResources from '../locales/ta.json'; 
import mlResources from '../locales/ml.json'; 

const allResources = {
    en:{translation:enresources},
    ar:{translation:arResources},
    ta:{translation:taResources},
    ml:{translation:mlResources},
}
console.log(i18n.options.resources);


i18n
  .use(initReactI18next) 
  .init({
    fallbackLng: 'en', 
    lng: localStorage.getItem('userLanguage') || 'en',
    resources:allResources, 
    direction:'rtl',
  });

export default i18n;
