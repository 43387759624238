import React, { useState } from "react";
import { footlist } from "../../data/footlist";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const ListFoot = () => {
  const [views, setViews] = useState(footlist.map(() => false));

  const toggleView = (index) => {
    const newViews = [...views];
    newViews[index] = !newViews[index];
    setViews(newViews);
  };

  return (
    <>
      <div className={`basis-2/3 lg:flex lg:flex-col flex-wrap gap-3 hidden `}>
        {footlist?.map((list, i) => (
          <ul key={i}>
            <h4 className="text-left text-sm">{list.title}</h4>
            <hr className="bg-gradient-to-r from-primary_color to-white h-[3px] w-1/3" />
            {list.sub.map((sub, j) => (
              <Link to={sub?.link} key={j}>
                <li className="text-left text-footerText text-xs mt-2 hover:cursor-pointer hover:text-white">
                  {sub.name}
                </li>
              </Link>
            ))}
          </ul>
        ))}
      </div>

      <div className={`flex flex-wrap gap-5 lg:hidden overflow-y-scroll`}>
        {footlist?.map((list, i) => (
          <ul key={i}>
            <div className="flex gap-3">
              <h4 className="text-left text-sm">{list.title}</h4>
              {views[i] ? (
                <IoIosArrowDown onClick={() => toggleView(i)} />
              ) : (
                <IoIosArrowForward onClick={() => toggleView(i)} />
              )}
            </div>
            <hr className="bg-gradient-to-r from-primary_color to-white h-[3px] w-1/3" />
            {views[i] &&
              list.sub.map((sub, j) => (
                <Link to={sub?.link} key={j}>
                  <li className="text-left text-footerText text-xs mt-2 hover:cursor-pointer hover:text-white">
                    {sub.name}
                  </li>
                </Link>
              ))}
          </ul>
        ))}
      </div>
    </>
  );
};

export default ListFoot;
