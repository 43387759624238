import React, { useEffect, useState } from "react";
import image from "../assets/services/hospital_bg_banner.png";
import TopBanner from "../components/common/TopBanner";

import h1img from "../assets/Hospitals/h1.png";
import h2img from "../assets/Hospitals/h2.png";
import h5img from "../assets/Hospitals/h5.png";
import { useTranslation } from "react-i18next";

import HospitalCard from "../components/Hospitals/HospitalCard";
import { Blog } from "../data/Blogs";
import ServiceRelatedBlogCard from "../components/Services/ServiceRelatedBlogCard";
import { Link } from "react-router-dom";
function Hospitals() {
  const [place, setPlace] = useState("All");
  const [speciality, setSpeciality] = useState("All");
  const { t } = useTranslation();
  const words = t("newsBlogs.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  const initHospData = [
    {
      id: 1,
      name: "SP Fort Hospital",
      location: "Thiruvananthapuram",
      image: `${h1img}`,
      phone: "0471 2450540",
      place: "Kerala",
      speciality: "Orthopedics",
      link:"https://maps.app.goo.gl/cgrn1tmNRKwJm6L48",
      url:"https://www.spfort.com/"
    },
    {
      id: 2,
      name: "SP Health Plus",
      location: "Thiruvananthapuram",
      image: `${h2img}`,
      phone: "+91 471 245 0850",
      place: "Kerala",
      speciality: "Orthopedics",
      link:"https://maps.app.goo.gl/4QpHLTWjXCvrzkNr8",
      url:"https://www.spfort.com/"
    },
    {
      id: 3,
      name: "SP WELL FORT",
      location: "Thiruvananthapuram",
      image: `${h5img}`,
      phone: "0471 4567890",
      place: "Kerala",
      speciality: "Orthopedics",
      link:"https://www.google.com/maps/dir/8.401648,77.0871199/spwellfort/@8.4574568,76.9484953,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b05bb7c1cf4b7e9:0x1305a3c64e0470a5!2m2!1d76.9754374!2d8.5121696?entry=ttu",
      url:"https://spwellfort.com/"
    },

    // { id: 4, name: 'Dummy', location: 'Thiruvananthapuram', image: `${h5img}`, phone: '0471 4567890', place: 'Kerala', speciality: 'Neurology' },
    // { id: 5, name: 'Dummy1', location: 'banglore', image: `${h5img}`, phone: '0471 4567890', place: 'Banglore', speciality: 'Dermatology' },
    // { id: 6, name: 'Dummy2', location: 'chennai', image: `${h5img}`, phone: '0471 4567890', place: 'Tamil nadu', speciality: 'Cardiology' },
  ];

  const [hospData, setHospData] = useState(initHospData);

  const places = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Ahmedabad",
    },
    {
      id: 3,
      name: "Banglore",
    },
    {
      id: 4,
      name: "Kerala",
    },
    {
      id: 5,
      name: "Tamil nadu",
    },
  ];
  const specialities = [
    {
      id: 5,
      name: "All",
    },
    {
      id: 1,
      name: "Orthopedics",
    },
    {
      id: 2,
      name: "Cardiology",
    },
    {
      id: 3,
      name: "Dermatology",
    },
    {
      id: 4,
      name: "Neurology",
    },
  ];

  useEffect(() => {
    if (place === "All" && speciality === "All") {
      setHospData(initHospData);
    } else if (place === "All" && speciality !== "All") {
      const hosps = initHospData.filter((el) => el.speciality === speciality);

      setHospData(hosps);
    } else if (place !== "All" && speciality === "All") {
      const hosps = initHospData.filter((el) => el.place === place);

      setHospData(hosps);
    } else {
      const hosps = initHospData.filter(
        (el) => el.place === place && el.speciality === speciality
      );

      setHospData(hosps);
    }
  }, [place, speciality]);

  return (
    <div>
      <TopBanner image={image} title="Our Hospitals" />
      <div className="w-full h-auto max-w-common mx-auto p-4 sm:p-0 flex sm:gap-x-8 my-20 ">
        {/* <div className="w-1/3 lg:w-1/4 hidden sm:block">
          <div className="bg-primary_color p-6 pb-28 rounded-xl ">
            <div className="flex flex-col items-start">
              <h1 className="text-2xl text-[#dadada] font-bold">Filter by Place</h1>
              <ul className="mt-3 flex flex-col items-start pointer">
                {places.map((item, i) => (

                  <li key={i}  onClick={() => setPlace(item.name)}
                  className={` mt-2  text-lg  cursor-pointer ${item.name === place ? 'text-white font-bold' : 'text-white font-[300]'}`}>
                    {item.name}
                  </li>

                ))}
              </ul>
            </div>
            <div className="mt-10 flex flex-col items-start">
              <h1 className="text-2xl text-[#dadada] font-bold">Filter by Speciality</h1>
              <ul className="mt-3 flex flex-col items-start pointer">
                {specialities.map((item, i) => (

                  <li key={i} onClick={() => setSpeciality(item.name)}
                    className={` mt-2  text-lg  cursor-pointer ${item.name === speciality ? 'text-white font-bold' : 'text-white font-[300]'}`} >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div> */}

        <div className="w-full  flex items-start max-md:items-center flex-col ">
          <h1 className="text-2xl md:text-headings leading-7 font-semibold">
            Our <span className="text-primary_color">Group</span> of Hospitals
          </h1>
          <h2 className="text-xl md:text-subHeading font-light  mt-2">
            learn more about us
          </h2>

          {/* Mobile filter */}
          {/* <div className="bg-primary_color mt-4 rounded-xl w-full h-[60px] flex sm:hidden justify-around items-center ">
            <select
              onClick={(e) => setPlace(e.target.value)}
              className="w-fit p-2 outline-none rounded-lg"
            >
              <option value="All">Select Place</option>
              {places.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>

            <select
              onClick={(e) => setSpeciality(e.target.value)}
              className="w-fit p-2 outline-none rounded-lg"
            >
              <option value="All">Select Speciality</option>
              {specialities.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div> */}

          {hospData?.length > 0 ? (
            <div className="w-full h-auto flex flex-wrap gap-16 mt-10 md:mt-14">
              {hospData?.map((item, i) => (
                <HospitalCard
                  key={i}
                  image={item?.image}
                  name={item?.name}
                  location={item?.location}
                  phone={item?.phone}
                  mapLink={item?.link}
                  link={item?.url}
                />
              ))}
            </div>
          ) : (
            <div className="w-full h-[500px] flex items-center justify-center ">
              <p className="text-2xl font-semibold text-[#9E9E9E]">
                Not Available
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="my-20 md:my-36 flex flex-col max-w-common w-full mx-auto">
        {/* top part */}
        <div className="flex justify-between text-xl font-semibold ">
          <h1 className="text-3xl font-bold text-center capitalize max-md:text-2xl">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <div>
            <Link to={"/news"}>
              <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
                {t("seeMore")}
              </h4>
            </Link>
          </div>
        </div>

        {/* blogs listing */}
        {Blog?.map((items) => (
          <div key={items?.id}>
            <div className="w-full overflow-x-scroll">
              <div className="w-fit mt-8  gap-x-8 flex">
                <ServiceRelatedBlogCard
                  data={items}
                  title={items?.title}
                  date={items?.postDate}
                  catrgory={items?.category}
                  image={items?.icon}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Hospitals;
