import React, { useEffect, useState, useRef } from "react";
import director1 from "../assets/about-us/director1.png";
import Leader from "../components/leaderShip/Leader";
import Piller from "../components/about/Piller";

import { pillers } from "../data/pillers";
import OurHospitalCard from "../components/about/OurHospitalCard";
import TopBanner from "../components/common/TopBanner";
import image from "../assets/about-us/about_us_banner.png";

import logo1 from "../assets/Hospitals/h1.png";
import logo2 from "../assets/Hospitals/h2.png";
import logo5 from "../assets/Hospitals/h5.png";
import Card from "../components/home/ourGroupHospitals/Card";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { doctors } from "../components/home/department/departmentData";
import { Link } from "react-router-dom";

function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //scroll

  const scrollContainer = useRef();

  const scrollLeft = () => {
    scrollContainer?.current?.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainer?.current?.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {/* about us banner */}
      <TopBanner title="About Us" image={image} />

      {/* leadership team */}
      <div className="w-full mt-14 md:my-24">
        <Leader />
      </div>

      {/* foundation pillers */}
      <div className="mt-20 md:mt-32 flex flex-col max-w-common mx-auto items-center gap-y-2  pb-20">
        <h1 className="text-2xl md:text-headings font-semibold">
          <span className="text-primary_color">Foundation </span> Pillars
        </h1>
        <h2 className="text-xl md:text-subHeading font-light max-md:mb-10">
          learn more about us
        </h2>
        {pillers.map((piller) => (
          <Piller
            key={piller.id}
            no={piller.id}
            image={piller.image}
            title={piller.title}
            subTitle={piller.subTitle}
            description={piller.description}
          />
        ))}
      </div>

      {/* our hospitals */}
      {/* <div className="w-screen flex flex-col relative">
        <div className="relative w-full max-w-common mx-auto flex max-md:flex-col items-center justify-between max-md:gap-y-5 mt-32">
          <div className="">
            <h1 className="text-3xl font-bold md:text-start capitalize max-md:text-2xl">
               Our Group of
              <span className="text-primary_color">
                 Hospitals
              </span>
            </h1>
            <h6 className=" mt-1 text-lg font-light text-left max-md:text-center">
              
              Setting standards in quality health care
            </h6>
          </div>
          <div>
            <Link to={"/hospitals"}>
              <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_colors hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
                See More
              </h4>
            </Link>
          </div>
        </div>

        <div
          className="hidden sm:block cursor-pointer w-[40px] p-3 h-fit absolute top-[50%] left-3 md:left-10 rounded-full bg-primary_color  text-white outline-none focus:outline-none z-20"
          onClick={scrollLeft}
        >
          <IoIosArrowBack />
        </div>
        <div
          ref={scrollContainer}
          className="w-full overflow-x-scroll pl-10 max-md:pl-[25px] py-14 hide-scrollbar"
        >
          <div className="w-full flex flex-col md:flex-row justify-start md:ms-20 gap-10">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="md:w-[450px] pe-5 md:pe-0"
            >
              <Card
                name="SP Fort Hospital"
                location="Thiruvananthapuram"
                image={logo1}
                mapLink=""
                phone="1234567890"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="md:w-[450px] pe-5  md:pe-0"
            >
              <Card
                name="SP HealthPlus"
                location="Thiruvananthapuram"
                image={logo2}
                mapLink=""
                phone="1234567890"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="md:w-[450px] pe-5 md:pe-0"
            >
              <Card
                name="SP WELL FORT"
                location="Thiruvananthapuram"
                image={logo5}
                mapLink=""
                phone="1234567890"
              />
            </div>
          </div>
        </div>
        <div
          className="hidden sm:block cursor-pointer w-[40px] h-fit absolute top-[50%] right-3 md:right-10 rounded-full bg-primary_color p-3 text-white outline-none z-10"
          onClick={scrollRight}
        >
          <IoIosArrowForward />
        </div>
      </div> */}
    </div>
  );
}

export default AboutUsPage;
