import React from 'react';
import { IoMdCloseCircle } from "react-icons/io";

const VideoModal = ({ data, setModal }) => {
    return (
        <div className='w-[90vw] sm:w-[80vw] h-[50vh] sm:h-[70vh] bg-black rounded-lg relative p-5
    flex flex-col items-center justify-center'>
            <span
                onClick={() => setModal(false)}
                className='absolute top-3 right-3 cursor-pointer'>
                <IoMdCloseCircle color='#ffffff' size={24} />
            </span>

            <span className='text-white absolute top-3 left-3'># {data?.title}</span>

            <video 
            className='w-fit sm:w-[70vw] h-[40vh] sm:h-[50vh]'
            controls>
                <source src={data?.link} type="video/mp4"/>
            </video>

        </div>
    )
}

export default VideoModal