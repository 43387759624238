import img from '../assets/img.png'


export const testimonials = [
    {
        id: 1,
        pic: img,
        name: "Hari kumar v k",
        words: `A newly established multi-specialty hospital, highly hygienic and fully equipped, 
        with a dedicated team ready to assist you at every step. The doctors and staff members 
        are exceptionally kind and polite. My grandfather had surgery at the main hospital and 
        was subsequently referred here for further treatment. The experience is remarkable in 
        every aspect. The hospital boasts a pleasant and affordable restaurant that serves buffet 
        meals, adding to the convenience and comfort of patients and visitors..`
    },
    {
        id: 2,
        pic: img,
        name: "Prafin Das",
        words: `New hospital in Trivandrum, SP Medifort has found outstanding in their performance. 
        We had a visit at their Oncology department. Dr. Boban Thomas and his team continue their fantastic service as before.
        A team of assisting staffs are there in all respects, from the gate to all the departments.
        We get a Five star facilities within a reasonable price.
        All the very best …..`
    },
    {
        id: 3,
        pic: img,
        name: "Parvathy Subramany",
        words: `Newly Opened SP Medifort has been a well furnished with all medical 
        facilities and modern equipments.DR Boben Thomas has been a great person whom 
        i could tell as God because with his smiling face remove worriness of patient 
        and DR Teena too helps very well moreover Athira from Oncology Guidance Room 
        has been very helpful and Achu of chemo Department was very adjustive and cooperative. 
        Very humble and downtoearth too. Never feel awkard in these hospital.`
    },
    {
        id: 4,
        pic: img,
        name: "Dr. Anirudhmaadhav P A",
        words: `I realised the importance of muscle strengthening and conditioning after I 
        met Nandagopan sir. He doesn’t blindly make you do exercises. He puts in time to 
        understand your current physical state, and then curates a program for you making 
        sure you don't get burnt out. Kudos to sir and his team at Medifort. The new physiotherapy centre is superb.`
    },
    {
        id: 5,
        pic: img,
        name: "Tharabhai Lakshmi",
        words: `I was with Sri Nanda Gopan for the last two weeks for physiotherapy. 
        My right hand had some problem. First day when I visited He explained to me my 
        problem with the help of pictures which helped me to understand the problem nicely. 
        The girls who assisted me to do the procedure were extremely good. Hats off to the 
        team of Sri Nandagopan`
    },
]