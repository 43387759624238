import React, { useEffect, useState } from "react";
import TopBanner from "../components/common/TopBanner";
import image from "../assets/OurTeam/our_team_bg_banner.jpeg";
import "../components/OurTeam/OurTeam.css";
import grayLogo from "../assets/logo/gray_logo.png";
import polygon from "../assets/OurTeam/Polygon.png";
import { doctors } from "../components/home/department/departmentData";
import { Link } from "react-router-dom";
import ModalData from "../components/Modal";
import dummy from "../assets/p1.png";

function OurTeam() {
  // Helper function to chunk the array into rows of 3 doctors
  const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };
  // Split doctors into rows of 3
  const doctorRows = chunkArray(doctors, 3);
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = (data) => {
    setData(data);
    setOpen(true);
    console.log(data);
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <TopBanner image={image} title="Our Team" />
      <h1 className="text-mainHeading leading-7 max-md:text-headings mt-20 font-semibold">
        Our <span className="text-primary_color"> Experienced </span> Doctors
      </h1>
      <h2 className="text-subHeading max-md:text-2xl font-light mt-2">
        learn more about us
      </h2>

      {/* Top Docker view  */}
      <div className="hidden my-8 md:my-20 md:mb-28 md:flex flex-col max-w-common max-md:w-11/12 mx-auto h-fit relative overflow-clip">
        <div className="h-[370px] max-md:h-[200px] w-full flex flex-col justify-end items-center relative">
          <div className="h-[320px] max-md:h-[150px] flex justify-between w-full rounded-t-2xl rounded-b-3xl bg-primary_color">
            <img className="h-[280px]" src={grayLogo} alt="bg" />
            <img className="scale-x-[-1] h-[280px]" src={grayLogo} alt="card" />
          </div>
          <div className="h-full w-full absolute flex justify-around">
            {doctors.slice(0, 3).map((items) => (
              <Person
                image={items?.Image}
                name={items?.name}
                department={items?.department}
                data={items}
              />
            ))}
          </div>
          <div className="w-[1220px] z-20 absolute bottom-0 rounded-[100%]">
            <img className="w-full" src={polygon} alt="card" />
          </div>
        </div>
      </div>

      {/* ----------------24/08/2024 --------------------- */}
      {/* Top view doctors mobile view  */}
      <div className="mt-10 md:hidden">
        {doctorRows.slice(0, 3).map((row, rowIndex) => (
          <div
            className="md:my-20 max-w-[1200px] max-md:w-11/12 mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-x-10"
            key={rowIndex}
          >
            {row.map((doctor, docIndex) => (
              <div className="h-[400px] mb-16 md:mb-0">
                {/* <Link to={`/doctor-profile/${doctor.id}`}> */}
                <div
                  className={`h-[370px] relative flex items-end ${
                    docIndex === 1 ? "md:-mt-10" : ""
                  } justify-center`}
                  key={doctor?.id}
                >
                  <div className="h-[320px] w-full bg-primary_color  rounded-tl-[2rem] relative">
                    <img
                      className="h-[280px] absolute scale-x-[-1] top-0 right-0"
                      src={grayLogo}
                      alt="bg"
                    />
                    {/* <img className="h-[80px] w-fit object-cover absolute scale-x-[-1] bottom-10 right-0" src={grayLogo} alt="bg" /> */}
                  </div>
                  <Link
                    to={`/doctor-profile/${doctor?.id}`}
                    className="absolute w-full h-full"
                  >
                    <img
                      className=" w-full h-full bottom-0 object-cover"
                      src={doctor?.Image}
                      alt={doctor.name}
                    />
                  </Link>

                  <div className="w-11/12 flex flex-col justify-center items-start pl-10 h-[65px] bg-primary_color backdrop-blur-sm bg-opacity-55 rounded-l-full absolute z-30 bottom-[25px] right-0">
                    <h2 className="text-lg font-medium text-white leading-5">
                      {doctor?.name}
                    </h2>
                    <h3 className="text-white font-light leading-5">
                      {doctor?.department}
                    </h3>
                  </div>
                </div>
                <Link onClick={() => handleOpen(doctor)} className="">
                  <button className="border-primary_color border-2 hover:bg-primary_color hover:text-white py-4 w-full mt-1 rounded-b-[2rem] text-sm font-semibold text-primary_color hover:scale-105 ease-in-out duration-300">
                    Book an Appointment
                  </button>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* ----------------end--------------------- */}

      {/* Second layer */}
      <div className="mt-10">
        {doctorRows.slice(1).map((row, rowIndex) => (
          <div
            className="md:my-20 max-w-[1200px] max-md:w-11/12 mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-x-10"
            key={rowIndex}
          >
            {row.map((doctor, docIndex) => (
              <div className="h-[400px] mb-16 md:mb-0">
                {/* <Link to={`/doctor-profile/${doctor.id}`}> */}
                <div
                  className={`h-[370px] relative flex items-end ${
                    docIndex === 1 ? "md:-mt-10" : ""
                  } justify-center`}
                  key={doctor?.id}
                >
                  <div className="h-[320px] w-full bg-primary_color  rounded-tl-[2rem] relative">
                    <img
                      className="h-[280px] absolute scale-x-[-1] top-0 right-0"
                      src={grayLogo}
                      alt="bg"
                    />
                    {/* <img className="h-[80px] w-fit object-cover absolute scale-x-[-1] bottom-10 right-0" src={grayLogo} alt="bg" /> */}
                  </div>
                  <Link
                    to={`/doctor-profile/${doctor?.id}`}
                    className="absolute w-full h-full"
                  >
                    <img
                      className=" w-full h-full bottom-0 object-cover"
                      src={doctor?.Image}
                      alt={doctor.name}
                    />
                  </Link>

                  <div className="w-11/12 flex flex-col justify-center items-start pl-10 h-[65px] bg-primary_color backdrop-blur-sm bg-opacity-55 rounded-l-full absolute z-30 bottom-[25px] right-0">
                    <h2 className="text-lg font-medium text-white leading-5">
                      {doctor?.name}
                    </h2>
                    <h3 className="text-white font-light leading-5">
                      {doctor?.department}
                    </h3>
                  </div>
                </div>
                <Link onClick={() => handleOpen(doctor)} className="">
                  <button className="border-primary_color border-2 hover:bg-primary_color hover:text-white py-4 w-full mt-1 rounded-b-[2rem] text-sm font-semibold text-primary_color hover:scale-105 ease-in-out duration-300">
                    Book an Appointment
                  </button>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>

      {
        <ModalData
          open={open}
          handleClose={handleClose}
          setOpen={setOpen}
          name={data?.name}
          department={data?.department}
          data={data}
        />
      }
    </div>
  );
}

export default OurTeam;

function Person({ image, name, department, data }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="w-1/4 max-md:w-1/3 relative">
        <div className="w-11/12 flex flex-col justify-center h-[65px] bg-primary_color backdrop-blur-sm bg-opacity-55 rounded-full absolute z-30 bottom-[70px] max-md:bottom-[40px] right-0 left-0 mx-auto">
          <h2 className="text-lg max-md:text-sm font-medium text-white leading-5 capitalize">
            {name}
          </h2>
          <h3 className="text-white font-light max-md:text-xs leading-5">
            {department}
          </h3>
        </div>
        <Link to={`/doctor-profile/${data?.id}`}>
          <img className="w-full h-full object-cover" src={image} alt={name} />
        </Link>
        <Link
          onClick={() => handleOpen()}
          className="absolute left-0 w-full bottom-5 z-40"
        >
          <button className="bg-primary_color py-3 w-full mt-4  rounded-full text-sm font-semibold text-white hover:scale-105 ease-in-out duration-300">
            Book an Appointment
          </button>
        </Link>
      </div>
      {
        <ModalData
          open={open}
          handleClose={handleClose}
          setOpen={setOpen}
          name={name}
          department={department}
        />
      }
    </>
  );
}
