import React from "react";
import ExperienceCard from "./ExperienceCard";
// import { experience } from "./experienceData";
import { useTranslation } from "react-i18next";
import clock from "../../../assets/experiece/clock.png";
import doctor from "../../../assets/experiece/health_worker 2.png";
import expe from "../../../assets/experiece/img3.png";
import { Link } from "react-router-dom";

const Experienced = () => {
  const { t } = useTranslation();
  const words = t("experienced.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  const experience = [
    {
      id: 1,
      title: t("experienced.head1"),
      description: t("experienced.body1"),
      icon: clock,
    },
    {
      id: 2,
      title: t("experienced.head2"),
      description: t("experienced.body2"),
      icon: doctor,
    },
    {
      id: 3,
      title: t("experienced.head3"),
      description: t("experienced.body3"),
      icon: expe,
    },
  ];

  return (
    <div className="max-w-common mx-auto p-3 md:pt-20 relative flex flex-col items-center max-md:mt-10">
      {/* Heading part */}
      <div className="flex w-full items-center justify-center">
        <div className="flex flex-col ">
          <h1 className="font-bold text-2xl md:text-headings font-sans">
            {firstPart}
            <span className="text-primary_color "> {secondPart} </span>
          </h1>

          <h6 className="mt-2 md:text-xl font-newFont font-thin">
            {t("experienced.subTitle")}
          </h6>
        </div>
      </div>

      <Link to={"/our-team"} className="mt-5">
        <div className="w-fit p-3 px-5 text-sm bg-primary_color text-white rounded-lg flex items-center gap-3 hover:scale-105 ease-in-out duration-300">
          {/* <FaPhoneAlt size={12} /> */}
          <span className="tracking-wider">Book an Appointment</span>
        </div>
      </Link>

      <div className="my-10 max-md:my-6">
        <img
          src={require("../../../assets/experiece/banner.png")}
          alt="banner"
          className="object-cover w-[1000px] max-md:hidden"
        />
      </div>
      <div className="absolute max-md:relative max-md:flex-col bottom-0 flex items-center justify-center gap-4">
        {experience?.map((item) => (
          <ExperienceCard key={item.id} data={item} />
        ))}
      </div>
      <img
        className="absolute h-[350px] max-md:h-[280px] -right-20 max-md:-right-0 -bottom-24 max-md:-bottom-20  -z-10 animate-spin"
        style={{ animationDuration: "30s" }}
        src={require("../../../assets/logo/moving_logo.png")}
        alt=""
      />
      <div className="absolute  left-0 right-0 mx-auto  moving-logo1 opacity-40 bottom-6 -z-10 max-md:hidden">
        <img
          className="object-contain  h-[150px]  animate-spin  max-md:hidden"
          style={{ animationDuration: "60s" }}
          src={require("../../../assets/logo/moving_logo.png")}
          alt=""
        />
      </div>
      <div className="absolute  right-0 -top-20  moving-logo2 opacity-40 bottom-6 -z-10 max-md:hidden">
        <img
          className="object-contain  h-[120px]  animate-spin  max-md:hidden"
          style={{ animationDuration: "60s" }}
          src={require("../../../assets/logo/moving_logo.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default Experienced;
