import React, { useState } from "react";
import { speciality } from "./specialitiesData";
import SideCard from "./SideCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Specialties = () => {
  const [select, setSelect] = useState(speciality[0]);
  const { t } = useTranslation();

  const words = t("specialties.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  const getFirstSentence = (text) => {
    const match = text.match(/[^.!?]+[.!?]/);
    return match ? match[0] : text;
  };

  return (
    <div className="max-w-common mx-auto p-3 py-10 ">
      {/* Heading part */}
      <div className="flex max-md:flex-col items-center justify-between">
        <div className="flex flex-col items-start max-md:items-center max-md:mb-6">
          <h1 className="font-bold text-2xl md:text-headings font-sans">
            {firstPart}
            <span className="text-primary_color "> {secondPart}</span>
          </h1>

          <h6 className="mt-2 md:text-xl font-newFont font-thin">
            {t("specialties.subTitle")}
          </h6>
        </div>
        <div>
          <Link to={"/specialties"}>
            <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
              {t("seeMore")}
            </h4>
          </Link>
        </div>
      </div>

      <div className="w-full mt-10 flex items-center gap-10">
        <div className="md:w-1/4">
          {speciality?.slice(0, 6).map((items, index) => (
            <div
            data-aos="fade-right"
            data-aos-duration={index * 200 + 100}
              onClick={(e) => setSelect(items)}
              key={items?.id}
              className="flex flex-col "
            >
              <SideCard data={items} index={index} selectedData={select} />
            </div>
          ))}
        </div>
        <div className="md:w-3/4  relative max-md:hidden ">
          <img
            src={select?.image}
            alt="banner"
            className="w-full md:h-[500px]  object-cover rounded-xl"
          />
          <div className="absolute w-full h-full bg-gradient-to-t from-black/80 to-transparent top-0 right-0 rounded-2xl"></div>
          <div className="absolute bottom-0 flex flex-col items-start p-10 text-white">
            <h1 className="font-bold text-start text-3xl md:text-[40px]">
              {select?.Heading}
            </h1>
            {select?.content.length > 0 &&
              select?.content?.map((items,index) => (
                <h1
                  key={index}
                  className="mt-5 font-thin text-wrap text-start text-xl"
                >
                  {getFirstSentence(items?.content)}...{" "}
                  <Link to={'/specialties'} className="text-blue-500 cursor-pointer">see more</Link>
                </h1>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialties;
