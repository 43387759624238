import React, { useRef } from "react";
import BlogCard from "./BlogCard";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { Blog } from "../../../data/Blogs";

const NewsBlogs = () => {
  const { t } = useTranslation();
  const words = t("newsBlogs.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  //scroll

  const scrollContainer = useRef();

  const scrollLeft = () => {
    scrollContainer?.current?.scrollBy({
      left: -415,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainer?.current?.scrollBy({
      left: 415,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative">
      {/* heading part */}
      <div className=" max-w-common mx-auto flex max-md:flex-col items-center justify-between max-md:gap-y-5">
        <div>
          <h1 className="text-3xl font-bold text-center capitalize max-md:text-2xl">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <h6 className=" mt-1 text-lg font-light text-left max-md:text-center">
            {t("newsBlogs.subTitle")}
          </h6>
        </div>
        <div>
          <Link to={"/news"}>
            <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
              {t("seeMore")}
            </h4>
          </Link>
        </div>
      </div>
      {/* Blog part  */}
      <div className="w-screen flex relative">
        <div
          className="cursor-pointer w-[40px] p-3 h-fit absolute top-[50%] left-3 md:left-14 rounded-full bg-primary_color  text-white outline-none focus:outline-none z-20"
          onClick={scrollLeft}
        >
          <IoIosArrowBack />
        </div>
        <div
          ref={scrollContainer}
          className=" w-full overflow-x-scroll pl-10 max-md:pl-5 py-14 hide-scrollbar"
        >
          <div className="w-fit flex justify-start gap-x-16 md:ps-24">
            {Blog.map((items) => (
              <BlogCard key={items?.id} data={items} />
            ))}
          </div>
        </div>
        <div
          className="cursor-pointer w-[40px] h-fit absolute top-[50%] right-3 md:right-14 rounded-full bg-primary_color p-3 text-white outline-none z-10"
          onClick={scrollRight}
        >
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default NewsBlogs;
