import React, { useState } from 'react'
import SelectSpecialities from '../../components/doctor/SelectSpecialities'
import Switch from "react-switch";
import SearchDoctorsAndSpecialities from '../../components/doctor/SearchDoctorsAndSpecialities'
import DoctorCard from '../../components/doctor/DoctorCard';
import SpecializedGrid from '../../components/doctor/SpecializedGrid';
import { FaArrowUpLong } from "react-icons/fa6";
import SearchBySymptom from '../../components/doctor/SearchBySymptom';
import { alphabets } from '../../data/alphabets'

function FindADoctor() {

    const [checked, setChecked] = useState(false)
    return (
        <div className='max-w-common mx-auto'>
            {/* header */}
            <div className='flex justify-between mt-[12rem] py-5'>
                <div className='flex flex-col items-start'>
                    <p>
                        Medical Departments & Centers
                    </p>
                    <h1 className='text-mainHeading'>
                        Find a Doctor
                    </h1>
                </div>
                <div className='flex flex-col items-end'>
                    <p >
                        Dont know which doctor to consult ?
                    </p>
                    <SearchBySymptom />

                </div>
            </div>

            <div className='flex mt-10 justify-end'>
                <div className='w-4/12 flex flex-col items-start pr-20 '>
                    <p className='text-gray-600 font-light'>
                        Filters applied
                    </p>
                    <p className='font-light text-paragraph mt-3'>
                        sorting by <span className='font-normal'>Speciality</span>
                    </p>
                    <p className='font-light text-paragraph mt-1'>
                        Name starting with letter <span className='font-normal'>N</span>
                    </p>
                    <SelectSpecialities />
                    <p className='text-gray-600 font-light mt-10 mb-6'>
                        Filter by name
                    </p>
                    <div>
                        <div className='ml-7 grid grid-cols-5 gap-3'>
                            {
                                alphabets.map((alphabet, index) => {
                                    return (
                                        <button key={index} className='border border-gray-600 rounded-full w-[40px] h-[40px]'>
                                            {alphabet.name}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='mt-10 flex gap-4 text-sm items-center'>
                        <p>Go to Top</p>
                        <button className='bg-primary_color w-[30px] h-[30px] flex items-center justify-center text-white rounded-full'>
                            <FaArrowUpLong size={15} />
                        </button>
                    </div>
                </div>
                <div className='w-8/12 flex flex-col items-start'>
                    <div className='flex items-center gap-x-6 w-full mb-7'>
                        <SearchDoctorsAndSpecialities />

                        <div className='flex items-center gap-x-1'>
                            <p className='whitespace-nowrap text-gray-500'>Sort by  :</p>
                            <span>Name</span>
                            <Switch
                                onChange={() => {
                                    setChecked(!checked)
                                }}
                                checkedIcon={null}
                                uncheckedIcon={null}
                                offColor='#852065'
                                onColor='#852065'
                                checked={checked}
                            />
                            <span>Speciality</span>
                        </div>
                    </div>
                    <p>
                        Search Results for <span className='text-paragraph font-medium'>Cherian</span>
                    </p>

                    {
                        checked ?
                            <SpecializedGrid />
                            :
                            <div className='grid w-full gap-5 grid-cols-3 mt-5'>
                                <DoctorCard />
                                <DoctorCard />
                                <DoctorCard />
                                <DoctorCard />
                                <DoctorCard />
                            </div>
                    }
                </div>
            </div>
        </div>
    )

}

export default FindADoctor
