"use client";
import { useEffect, useState } from "react";
import "./gallery.css";
import image1 from "../../assets/gallery/image 1.jpg";
import image2 from "../../assets/gallery/image 2.jpg";
import image3 from "../../assets/gallery/image 3.jpg";
import image4 from "../../assets/gallery/image 4.jpg";
import image5 from "../../assets/gallery/image 5.jpg";
import image6 from "../../assets/gallery/image 6.png";
import GalCarousel from "./GalCarousel";

const Gallery = () => {
  const [event, setEvent] = useState("All");
  const [modal, setModal] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const AllImages = [image1, image2, image3, image4, image5, image6];
  const E1Images = [image1, image2, image3];
  const E2Images = [image4, image5, image3, image2];
  const E3Images = [image2, image5, image3, image4];
  const E4Images = [image4, image3, image5, image2];
  const E5Images = [image4, image5, image3, image2];

  const [dispImgs, setDispImgs] = useState([...AllImages]);

  useEffect(() => {
    if (event === "All") {
      setDispImgs([...AllImages]);
    } else if (event === "Event 1") {
      setDispImgs([...E1Images]);
    } else if (event === "Event 2") {
      setDispImgs([...E2Images]);
    } else if (event === "Event 3") {
      setDispImgs([...E3Images]);
    } else if (event === "Event 4") {
      setDispImgs([...E4Images]);
    } else if (event === "Event 5") {
      setDispImgs([...E5Images]);
    }
  }, [event]);

  const initModal = (index) => {
    setImgIndex(index);
    setModal(true);
  };

  return (
    <div className="max-w-common mx-auto mt-16 p-4 flex flex-col items-center ">
      <div className="hidden w-full sm:w-fit min-w-[50vw] h-[5vh] mt-5 sm:flex items-center justify-evenly font-medium">
        <span
          onClick={() => setEvent("All")}
          className={`cursor-pointer ${
            event === "All" ? "text-primary_color" : "text-black"
          } `}
        >
          All
        </span>

        <div className="h-[20px] w-[1px] bg-primary_color"></div>

        <span
          onClick={() => setEvent("Event 1")}
          className={`cursor-pointer ${
            event === "Event 1" ? "text-primary_color" : "text-black"
          } `}
        >
          Event 1
        </span>

        <div className="h-[20px] w-[1px] bg-primary_color"></div>

        <span
          onClick={() => setEvent("Event 2")}
          className={`cursor-pointer ${
            event === "Event 2" ? "text-primary_color" : "text-black"
          } `}
        >
          Event 2
        </span>

        <div className="h-[20px] w-[1px] bg-primary_color"></div>

        <span
          onClick={() => setEvent("Event 3")}
          className={`cursor-pointer ${
            event === "Event 3" ? "text-primary_color" : "text-black"
          } `}
        >
          Event 3
        </span>

        <div className="h-[20px] w-[1px] bg-primary_color"></div>

        <span
          onClick={() => setEvent("Event 4")}
          className={`cursor-pointer ${
            event === "Event 4" ? "text-primary_color" : "text-black"
          } `}
        >
          Event 4
        </span>

        <div className="h-[20px] w-[1px] bg-primary_color"></div>

        <span
          onClick={() => setEvent("Event 5")}
          className={`cursor-pointer ${
            event === "Event 5" ? "text-primary_color" : "text-black"
          } `}
        >
          Event 5
        </span>
      </div>

      <div className="w-full flex sm:hidden mt-2 items-center justify-between">
        <select
          onChange={(e) => setEvent(e.target.value)}
          className="bg-gray text-black outline-none border border-[#585858] rounded-lg flex items-center justify-center p-3"
        >
          <option value="All"> All </option>
          <option value="Event 1"> Event 1 </option>
          <option value="Event 2"> Event 2 </option>
          <option value="Event 3"> Event 3 </option>
          <option value="Event 4"> Event 4 </option>
          <option value="Event 5"> Event 5 </option>
        </select>
      </div>

      {/* Gallery */}
      <div className="w-full mt-3 sm:mt-6 figure">
        {dispImgs?.map((item, i) => (
          <img
            key={i}
            src={item}
            className="pic"
            onClick={() => initModal(i)}
            alt="displayImage"
          />
        ))}
      </div>

      {modal && (
        <GalCarousel
          setModal={setModal}
          imgIndex={imgIndex}
          imgArray={dispImgs}
        />
      )}
    </div>
  );
};

export default Gallery;
