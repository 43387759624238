import React from "react";

const ExperienceCard = ({ data }) => {
  return (
    <div className="w-full md:w-1/3">
      <div className="bg-white cursor-pointer  hover:bg-primary_color hover:text-white text-sm ease-in-out duration-200 text-primary_color shadow-[0_5px_60px_-15px_rgba(0,0,0,0.3)] p-5 rounded-xl h-[150px] max-md:h-[220px] flex flex-col items-center justify-center">
        <div className="absolute max-md:relative mb-40 max-md:mb-5 border-4  bg-[#FFDAF3] border-white rounded p-2">
          <img src={data?.icon} alt="logo" className="w-12 object-cover p-2" />
        </div>
        <h2 className=" font-bold mb-2 max-md:text-base">{data?.title}</h2>
        <h5 className="text-wrap text-sm">
         {data?.description}
        </h5>
      </div>
    </div>
  );
};

export default ExperienceCard;
