import React from "react";
import Newsletter from "./Newsletter";
import ListFoot from "./ListFoot";

const TopFoot = () => {
  return (
    <div className="max-w-common mx-auto w-full h-full md:h-screen p-5 py-[40px]  text-white flex flex-col md:flex-row justify-between gap-7 ">
      <Newsletter />
      <ListFoot />
    </div>
  );
};

export default TopFoot;
