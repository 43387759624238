import i18n from "../../config/i18n";
import "./Nav.css";
import Dropdown from "./Dropdown";

import React, { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF, FaLocationDot } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [mobnav, setMobnav] = useState(false);

  const path = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("userLanguage", event.target.value);
  };

  const [dropArr, setDropArr] = useState([
    { title: "patientCare", value: false },
    { title: "services", value: false },
    { title: "hospitals", value: false },
    { title: "departments", value: false },
    { title: "booking", value: false },
    { title: "NIL", value: false },
    { title: "NIL", value: false },
    { title: "NIL", value: false },
  ]);

  useEffect(() => {
    const closedDrops = dropArr.map((el) => ({ ...el, value: false }));

    setDropArr([...closedDrops]);
  }, [path]);

  const chooseOne = (title, dropArr) => {
    const newDrop = dropArr.map((el) => {
      if (el?.title === title) {
        el.value = !el.value;
      } else {
        el.value = false;
      }

      return el;
    });

    setDropArr((prev) => [...newDrop]);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsScrolled(currentScrollPos > 0);
      if (currentScrollPos > prevScrollPos) {
        // Scrolling down
        setIsScrolled(false);
      } else {
        // Scrolling up
        setIsScrolled(true);
      }
      setPrevScrollPos(currentScrollPos);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleSmoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  // console.log(path?.pathname);
  return (
    <>
      <div className={`bg-primary_color text-white fixed w-full top-0 z-50 `}>
        <div className="bg-white/20 p-1.5 text-black ">
          <div className="flex  items-center rounded-lg  justify-between w-full max-w-common mx-auto px-3 gap-2">
            <div className="flex gap-2 items-center justify-center">
              {/* <Link to={""} className="text-white rounded-full flex justify-center items-center cursor-pointer">
                <FaXTwitter size={12} />
              </Link> */}
              <Link
                to={"https://www.youtube.com/@spmedifort"}
                className="text-white rounded-full flex justify-center items-center cursor-pointer"
              >
                <FaYoutube size={12} />
              </Link>
              <Link
                to={"https://www.facebook.com/p/SP-Medifort-61553020891684/"}
                className="text-white rounded-full flex justify-center items-center cursor-pointer"
              >
                <FaFacebookF size={12} />
              </Link>
              <Link
                to={
                  "https://www.linkedin.com/company/sp-medifort-hospital/?originalSubdomain=in"
                }
                className="text-white rounded-full flex justify-center items-center cursor-pointer"
              >
                <FaLinkedin size={12} />
              </Link>
              <Link
                to={"https://www.instagram.com/spmedifort/"}
                className="text-white rounded-full flex justify-center items-center cursor-pointer"
              >
                <FaInstagram size={12} />
              </Link>
              <Link
                to={
                  "https://www.google.com/maps?ll=8.484614,76.929411&z=20&t=m&hl=en&gl=IN&mapclient=embed&cid=16639426147576322759"
                }
                className="text-white rounded-full flex justify-center items-center cursor-pointer"
              >
                <FaLocationDot size={12} />
              </Link>
            </div>
            <div className="flex gap-3 ">
              <ul className="flex gap-2 text-white text-xs">
                <li className="hidden md:block">
                  <Link to={"/cover-privacy-policy.html"}>Privacy policy</Link>
                </li>
              </ul>
              <div className="vl"></div>
              <select
                value={i18n.language}
                onChange={handleChangeLanguage}
                className="bg-transparent hover:cursor-pointer focus:outline-none text-white text-xs"
              >
                <option value="en" className="text-black">
                  English
                </option>
                <option value="ar" className="text-black">
                  Arabic
                </option>
                <option value="ta" className="text-black">
                  Tamil
                </option>
                <option value="ml" className="text-black">
                  Malayalam
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="w-full h-20 bg-primary_color flex">
          <div className="w-full max-w-common mx-auto flex justify-between items-center px-3">
            <Link to={"/"} className="flex items-center gap-3">
              <img
                src={require("../../assets/Nav/mainlogo.png")}
                alt="logo"
                className="w-[100px] md:w-[120px] h-[75px] object-contain"
              />
            </Link>

            <div className="hidden sm:flex tracking-widest font-sans">
              {t("youFirstAlways")}
            </div>

            <div className="flex gap-4">
              <div className="hidden md:flex flex-col items-end justify-end ">
                <Link to={"/our-team"}>
                  <div className="w-fit p-2 border border-white rounded-lg flex items-center gap-3">
                    {/* <FaPhoneAlt size={12} /> */}
                    <span className="text-[12px]">Book an Appointment</span>
                  </div>
                </Link>
              </div>

              <div className="flex flex-col">
                <span className="text-center text-[10px] font-light font-san">
                  {t("splifeline")}
                </span>
                <a href="tel:0471 3100 100">
                  <div className="w-fit p-2 border border-white rounded-lg flex items-center gap-3">
                    <FaPhoneAlt size={12} />
                    <span className="text-[12px]">0471 3100 100</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="navSecond w-full h-10  flex">
          <div className="select-none hidden sm:visible w-full max-w-common mx-auto sm:flex justify-between items-center text-sm font-sans px-3">
            {/* About us */}
            <Link to={"about-us"}>
              <span className="flex items-center gap-2 hover:cursor-pointer">
                {t("about")}
              </span>
            </Link>

            <div className="vl"></div>

            {/* Service */}
            <Dropdown
              dropArr={dropArr}
              chooseOne={chooseOne}
              mobview={false}
              title="services"
            >
              <li
                onClick={() => navigate("/diagnostics")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                {t("navbar.diagnostics")}
              </li>
              <hr className="border-1 border-white"></hr>

              <li
                onClick={() => navigate("/emergency")}
                className="block px-4 py-2 hover:bg-[#99467F]  cursor-pointer"
              >
                {/* {t("navbar.services")} */}
                Emergency Care
              </li>
              <hr className="border-1 border-white"></hr>

              <li
                onClick={() => navigate("/preventive")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                {t("navbar.preventiveHealth")}
              </li>
            </Dropdown>

            <div className="vl"></div>

            {/* Department */}
            <Dropdown
              dropArr={dropArr}
              chooseOne={chooseOne}
              mobview={false}
              title="departments"
            >
              <li
                onClick={() => navigate("/departments")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                {t("navbar.departments")}
              </li>
              <hr className="border-1 border-white"></hr>

              <li
                onClick={() => navigate("/specialties")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                {t("navbar.ourSpecialties")}
              </li>
            </Dropdown>

            <div className="vl"></div>

            {/* Booking */}
            <Dropdown
              dropArr={dropArr}
              chooseOne={chooseOne}
              mobview={false}
              title="booking"
            >
              <li
                onClick={() => navigate("/our-team")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                Our Doctors
              </li>
            </Dropdown>

            <div className="vl"></div>

            {/* Patient login */}
            <span className="flex items-center gap-2 hover:cursor-pointer">
              <Link to={"/login"}>{t("navbar.login")}</Link>
              {/* <IoMdArrowDropdown />{" "} */}
            </span>

            <div className="vl"></div>

            {/* Hospitals */}
            <Dropdown
              dropArr={dropArr}
              chooseOne={chooseOne}
              mobview={false}
              title="hospitals"
            >
              <li
                onClick={() => navigate("/hospitals")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                {t("navbar.ourHospitals")}
              </li>
              <hr className="border-1 border-white"></hr>

              <li
                onClick={() => navigate("/insurance")}
                className="block px-4 py-2 hover:bg-[#99467F] cursor-pointer"
              >
                {/* {t("navbar.preventiveHealth")} */}
                Insurance
              </li>
            </Dropdown>

            <div className="vl"></div>

            {/* News */}
            <span className="flex items-center gap-2 hover:cursor-pointer">
              <Link to={"/news"}>{t("navbar.newsBlogs")}</Link>
              {/* <IoMdArrowDropdown />{" "} */}
            </span>

            <div className="vl"></div>

            {/* Career */}
            <Link to={"join-us"}>
              <span className="flex items-center gap-2 hover:cursor-pointer">
                {t("navbar.careers")}
              </span>
            </Link>

            <div className="vl"></div>

            {/* virtual tour */}
            <a href={"https://spmedifort.com/virtual-tour/"}>
              <span className="flex items-center gap-2 hover:cursor-pointer">
                {"Virtual Tour"}
              </span>
            </a>

            <div className="vl"></div>

            {/* Gallery  */}
            <Link to={"gallery"}>
              <span className="flex items-center gap-2 hover:cursor-pointer">
                Gallery
              </span>
            </Link>

            <div className="vl"></div>

            {/* Contact us  */}
            <Link to={"contact-us"}>
              <span className="flex items-center gap-2 hover:cursor-pointer">
                {t("contact")}
              </span>
            </Link>
          </div>

          {/* Mobile view */}
          <div className="relative sm:hidden w-full max-w-common mx-auto flex justify-between items-center text-sm font-sans px-3">
            {mobnav ? (
              <span onClick={() => setMobnav(false)}>
                <IoClose size={24} />
              </span>
            ) : (
              <span onClick={() => setMobnav(true)}>
                <RxHamburgerMenu size={24} />
              </span>
            )}
            <div className="tracking-widest font-sans">
              {t("youFirstAlways")}
            </div>

            {mobnav && (
              <div
                className="w-full z-42 h-screen bg-primary_color absolute sm:hidden flex flex-col 
              top-10 left-0 gap-5 items-center px-5"
              >
                <ul className="flex flex-col gap-5 mt-5">
                  <Link to={"/"}>
                    <li
                      className={`${
                        path?.pathname === "/" ? "text-nav_color" : "text-white"
                      }`}
                      onClick={() => setMobnav(false)}
                    >
                      {t("home")}
                    </li>
                  </Link>
                  <Link to={"/about-us"}>
                    <li
                      className={`${
                        path?.pathname === "/about-us"
                          ? "text-nav_color"
                          : "text-white"
                      }`}
                      onClick={() => setMobnav(false)}
                    >
                      {t("about")}
                    </li>
                  </Link>
                </ul>

                {/* Service */}
                <Dropdown
                  dropArr={dropArr}
                  chooseOne={chooseOne}
                  onClick={() => setMobnav(false)}
                  mobview={true}
                  title="services"
                >
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/diagnostics");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    {t("navbar.diagnostics")}
                  </li>
                  <hr className="border-1 border-primary_color"></hr>
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/emergency");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    Emergency Care
                  </li>
                  <hr className="border-1 border-primary_color"></hr>
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/preventive");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    {t("navbar.preventiveHealth")}
                  </li>
                </Dropdown>

                {/* DEpartment */}
                <Dropdown
                  dropArr={dropArr}
                  chooseOne={chooseOne}
                  mobview={true}
                  title="departments"
                  onClick={() => setMobnav(false)}
                >
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/departments");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    {t("navbar.departments")}
                  </li>
                  <hr className="border-1 border-primary_color"></hr>
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/specialties");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    {t("navbar.ourSpecialties")}
                  </li>
                </Dropdown>

                {/* Booking */}
                <Dropdown
                  dropArr={dropArr}
                  chooseOne={chooseOne}
                  mobview={true}
                  title="booking"
                  onClick={() => setMobnav(false)}
                >
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/our-team");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    Our Doctors
                  </li>
                </Dropdown>

                {/* Patient login */}
                <span
                  onClick={() => setMobnav(false)}
                  className="flex items-center gap-2 hover:cursor-pointer"
                >
                  <Link to={"/login"}>{t("navbar.login")}</Link>
                  {/* <IoMdArrowDropdown />{" "} */}
                </span>

                {/* Hospitals */}
                <Dropdown
                  dropArr={dropArr}
                  chooseOne={chooseOne}
                  mobview={true}
                  title="hospitals"
                  onClick={() => setMobnav(false)}
                >
                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/hospitals");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    {t("navbar.ourHospitals")}
                  </li>

                  <hr className="border-1 border-primary_color"></hr>

                  <li
                    onClick={() => {
                      setMobnav(false);
                      navigate("/insurance");
                    }}
                    className="block px-4 py-2 hover:bg-[#99467F] text-black cursor-pointer"
                  >
                    Insurance
                  </li>
                </Dropdown>

                {/* News */}
                <span
                  onClick={() => setMobnav(false)}
                  className="flex items-center gap-2 hover:cursor-pointer"
                >
                  <Link to={"/news"}>{t("navbar.newsBlogs")}</Link>
                  {/* <IoMdArrowDropdown />{" "} */}
                </span>

                {/* Career */}
                <Link onClick={() => setMobnav(false)} to={"join-us"}>
                  <span className="flex items-center gap-2 hover:cursor-pointer">
                    {t("navbar.careers")}
                  </span>
                </Link>

                {/* virtual tour */}
                <a
                  onClick={() => setMobnav(false)}
                  href={"https://spmedifort.com/virtual-tour/"}
                >
                  <span className="flex items-center gap-2 hover:cursor-pointer">
                    {"Virtual Tour"}
                  </span>
                </a>
                {/*Gallery  */}
                <Link onClick={() => setMobnav(false)} to={"gallery"}>
                  <span className="flex items-center gap-2 hover:cursor-pointer">
                    Gallery
                  </span>
                </Link>

                {/* Contact us  */}
                <Link onClick={() => setMobnav(false)} to={"contact-us"}>
                  <span className="flex items-center gap-2 hover:cursor-pointer">
                    {t("contact")}
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
