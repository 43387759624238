import React from "react";
import TopBanner from "../components/common/TopBanner";
import ServiceCard from "../components/Services/ServiceCard";
import { servicesData } from "../data/services";
import bgBanner from "../assets/services/bg.jpeg";
import { BiRightArrow } from "react-icons/bi";
import { FaLongArrowAltRight } from "react-icons/fa";
import ServiceRelatedBlogCard from "../components/Services/ServiceRelatedBlogCard";
import { Link } from "react-router-dom";
import { Blog } from "../data/Blogs";
import { useTranslation } from "react-i18next";

function Services() {
    const { t } = useTranslation();
    const words = t("newsBlogs.title")?.split(" ");
    const firstPart = words.slice(0, words.length - 1).join(" ");
    const secondPart = words[words.length - 1];
  return (
    <div>
      <TopBanner title="Our Services" image={bgBanner} />
      <h1 className="mt-14 text-mainHeading max-md:text-headings font-semibold ">
        Explore Our <span className="text-primary_color">Services</span>
      </h1>
      <h2 className="text-subHeading max-md:text-xl font-light mt-1">
        learn more about us
      </h2>
      <div className="grid mt-12 grid-cols-1 max-w-common max-md:w-11/12 mx-auto gap-y-14">
        {servicesData.map((service) => (
          <ServiceCard
            key={service.id}
            title={service.title}
            image={service.image}
            description={service.description}
          />
        ))}
      </div>

      <div className="my-20 flex flex-col max-w-common max-md:w-11/12 mx-auto">
        {/* top part */}
        <div className="flex justify-between text-xl font-semibold ">
          <h1 className="text-3xl font-bold text-center capitalize max-md:text-2xl">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <div>
            <Link to={"/news"}>
              <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
                {t("seeMore")}
              </h4>
            </Link>
          </div>
        </div>

        {/* blogs listing */}
        {Blog?.map((items) => (
          <div key={items?.id}>
            <div className="w-full overflow-x-scroll">
              <div className="w-fit mt-8  gap-x-8 flex">
                <ServiceRelatedBlogCard
                  data={items}
                  title={items?.title}
                  date={items?.postDate}
                  catrgory={items?.category}
                  image={items?.icon}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
