import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, Modal, TextField } from "@mui/material";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const ModalData = ({ handleClose, open, setOpen, name, data, department }) => {
  console.log(name, department, data);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const RecaptchaKey = process.env.REACT_APP_G_SITE_KEY;

  useEffect(() => {
    emailjs.init(PublicKey);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "Booking",
    message: `
      Doctor Name: ${data?.name || "N/A"},
    Department: ${data?.department || "N/A"},
      `,
  });

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      message: `
      Doctor Name: ${data?.name || "N/A"},
      Department: ${data?.department || "N/A"},
    `,
    }));
  }, [data]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      return toast.error("Please complete the reCAPTCHA");
    }
    const nameRegex = /^[a-zA-Z\s'-]{2,50}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    if (!nameRegex.test(formData?.name)) {
      return toast.info("Enter a valid name");
    }

    if (!emailRegex.test(formData?.email)) {
      return toast.info("Enter a valid email");
    }

    if (!mobileNumberRegex.test(formData?.phone)) {
      return toast.info("Enter a valid Phone number");
    }

    console.log(formData);
    setLoading(true);

    emailjs
      .sendForm(ServiceId, TemplateId, e.target, PublicKey)
      .then((result) => {
        console.log("Email sent successfully!");

        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        toast.success("Message Send Successfully");
        setLoading(false);
        setOpen(false);
        console.log("Form submitted:", result);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        toast.error("Please try after sometime");
        setLoading(false);
      });
  };

  // for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: 500,
    borderRadius: 2,
    p: 4,
    "@media (max-width: 768px)": {
      width: "90vw",
    },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col ">
            <h4 className="text-sm font-medium text-primary_color">
              Booking Info
            </h4>
            <p className="text-lg  font-medium">
              Please enter your details to finish booking{" "}
            </p>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-y-4 mt-5"
            >
              <TextField
                size="small"
                id="outlined-search"
                label="Full name"
                type="name"
                name="name"
                onChange={handleChange}
                value={formData?.name}
              />
              <TextField
                size="small"
                id="outlined-search"
                label="Email"
                type="email"
                name="email"
                onChange={handleChange}
                value={formData?.email}
              />
              <TextField
                size="small"
                id="outlined-search"
                label="Phone Number"
                type="phone"
                name="phone"
                onChange={handleChange}
                value={formData?.phone}
              />
              {/* Hidden input for the message */}
              <input type="hidden" name="message" value={formData?.message} />

              {/* Add the reCAPTCHA component */}
              <ReCAPTCHA
                sitekey={RecaptchaKey}
                onChange={handleRecaptchaChange}
              />

              <button className="border-none px-5 py-2 rounded-lg text-sm text-white bg-primary_color  w-fit">
                Submit
              </button>
            </form>
            <div className="h-[1px] bg-gray-700 w-full my-5"></div>
            <a
              onClick={() => setOpen(false)}
              href="tel: +91 0471 3100 100"
              className="w-full"
            >
              <button className="border-none w-full px-4 py-3 rounded-lg text-sm text-white bg-primary_color font-medium">
                <span className=""> Call - 471 3100 100</span>
              </button>
            </a>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalData;
