import React from "react";
import { Link } from "react-router-dom";

function BlogCard(props) {
  return (
    <div className="w-[430px] overflow-hidden max-md:w-[350px] h-[360px] shadow-[6px_6px_70px_0px_rgba(0,0,0,0.2)] rounded-t-2xl">
      <Link to={`/news/${props?.data?.id}`}>
        <img
          className="w-[430px] h-[210px] object-cover rounded-t-2xl cursor-pointer hover:scale-105 ease-in-out duration-200"
          src={props?.data?.icon}
          alt=""
        />
      </Link>
      <div className="p-6">
        <div>
          <h2 className="truncate text-2xl max-md:text-xl font-semibold text-primary_color text-left mt-3 line-clamp-2">
            {props?.data?.title}
          </h2>
          <p className="text-[18px] max-md:text-base font-light text-left mt-2">
            <span className="font-medium text-black/50">Publishing Date :</span>{" "}
            {props?.data?.postDate}
          </p>
          <p className="text-[18px] max-md:text-base font-light text-left ">
            <span className="font-medium text-black/50">Writer :</span>{" "}
            {props?.data?.author}
          </p>
        </div>
      </div>
      <div className="flex flex-col -mt-[180px] -ml-[8px] ">
        <div className="flex items-center h-[40px] w-fit mr-5">
          <span className="bg-primary_color h-[40px] flex items-center">
            <h2 className="  text-white text-base  px-6 line-clamp-1">
              {props?.data?.category}
            </h2>
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: "20px solid transparent",
              borderBottom: "20px solid transparent",
              borderLeft: `20px solid rgb(133 32 101)`,
            }}
          >
            {" "}
          </div>
        </div>
        <div
          style={{
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderRight: "8px solid #5B0841",
          }}
          className="-z-10 -mt-[10px]"
        >
          {" "}
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
