export const servicesData = [
    {
        id: 1,
        title: "Home Health Services",
        image: "cardiology.png",
        description: `At SP Medifort, our Home Health Services bring the expertise of our skilled healthcare professionals directly to you, ensuring personalized care in the comfort of your own home. Whether you're recovering from surgery, managing a chronic condition, or needing assistance with daily activities, our dedicated team is here to support you every step of the way. From skilled nursing care to physical therapy and medical social work, we provide comprehensive services tailored to your individual needs. Experience the convenience and compassion of home-based care with SP Medifort's Home Health Services.`,
    },
    {
        id: 2,
        title: "Diagnostic Imaging Services",
        image: "dermatology.jpeg",
        description: `Radiology services are housed in a 5000 square feet wing at SP Medifort. The equipment and technology here are among the best by international standards. Across the spectrum of imaging, the facility is digital with IT integration enabling transmission and archival of images and reports.`,

    },
    {
        id: 3,
        title: "3 Tesla MRI Scanner",
        image: "neurology.jpeg",
        description: `Radiology services at SP Medifort boast a dedicated 5000 square feet wing, showcasing state-of-the-art equipment and technology, including a cutting-edge 3 Tesla MRI scanner. Renowned for its precision and clarity, this advanced scanner sets international standards in imaging excellence. Fully integrated with IT systems, our facility ensures seamless transmission and archival of images and reports, delivering top-notch diagnostic capabilities to our patients.`,

    },

]