import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/Store.js"
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} >
            <ToastContainer />
            <App />
        </PersistGate>
    </Provider>
);

AOS.init();