import React, { useEffect, useState } from "react";
import { BsFileMedicalFill } from "react-icons/bs";
import De3 from "../assets/department/De3.jpg";
import De9 from "../assets/department/De9.jpg";
import banner from "../assets/specialities/Banner1.png";

import SpecialityCard from "../components/specialities/SpecialityCard";
import TopBanner from "../components/common/TopBanner";
import { speciality } from "../components/home/specialities/specialitiesData";
import { useLocation } from "react-router-dom";
import { CiSearch } from "react-icons/ci";

function Specialities() {
  const [specialitie, setSpecialtie] = useState(speciality);

  const CustomInput = () => {
    const [search, setSearch] = useState("");
    const Search = () => {
      const filterData = speciality.filter((item) =>
        item?.Heading.toLowerCase().includes(search.toLowerCase())
      );
      setSpecialtie(filterData);
    };
    return (
      <div className="mt-28 max-md:mt-20 w-fit relative bottom-4">
        <input
          className="px-14 py-4 w-[460px] max-md:w-[310px] border-2 border-primary_color border-opacity-50 rounded-lg focus:outline-none"
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Speciality"
        />
        <div
          className="pl-3"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <BsFileMedicalFill size={32} className=" text-primary_color" />
        </div>
        <div
          className="pe-3"
          style={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
          }}
        >
          <CiSearch onClick={Search} size={32} className="cursor-pointer text-primary_color" />
        </div>
      </div>
    );
  };

  const location = useLocation();

  useEffect(() => {
    const scrollToHashElement = () => {
      if (location.hash) {
        const elementId = location.hash.substring(1);
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    const timeoutId = setTimeout(scrollToHashElement, 100);

    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <div className="w-full ">
      <TopBanner title="Specialties" image={banner} />
      <div className="max-w-common mx-auto w-full max-md:w-10/12 flex flex-col items-center relative ">
        <CustomInput />
        <div className="grid grid-cols-1 w-full mt-6 gap-y-10 max-md:gap-y-6 mb-20 ">
          {specialitie?.map((item, i) => (
            <SpecialityCard key={i} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Specialities;
