import { Link } from "react-router-dom";
import "./QuickCard.css";

const QuickCard = ({ data, index }) => {
  const duration = index * 200 + 100;
  const delay = `${duration}`;

  return (
    <Link to={data?.link}>
      <div
        className="quick_card w-full md:w-[170px] md:h-[130px] flex flex-col items-center justify-center gap-3 p-5 
    hover:scale-105 ease-in-out duration-300 hover:text-primary_color hover:cursor-pointer"
        data-aos="fade-up"
        data-aos-duration={delay}
      >
        <div>{data?.icon}</div>
        <div className="text-xs ">{data?.title}</div>
      </div>
    </Link>
  );
};

export default QuickCard;
