import React from 'react';
import { BsPlayCircle } from "react-icons/bs";

const Film = ({data, setCurrVid, setModal}) => {

    const watchVideo = (data)=>{
        setCurrVid(data)
        setModal(true)
    }

  return (
    <div className='w-full sm:w-[18vw] h-[40vh] bg-[#F6F7F9] 
    p-3 flex flex-col items-center gap-2 relative' >
        <img 
        className='w-full h-[25vh] object-cover brightness-75'
        src={require('../../assets/corporate/nurse.png')} />

        <div className='w-full flex flex-col items-start'>
            <span className='text-primary_color text-xs'>{data?.date}</span>
            <span className='text-primary_color font-bold'>{data?.title}</span>
            <span 
            onClick={()=>watchVideo(data)}
            className='text-primary_color text-xs mt-3 cursor-pointer'>
                Watch Now
            </span>
        </div>

        <span className='absolute top-20 cursor-pointer'>
            <BsPlayCircle onClick={()=>watchVideo(data)} size={56} color='#EF56BE'/>
        </span>

    </div>
  )
}

export default Film