import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import { IoCall } from 'react-icons/io5'

function HospitalCard({
    name, location, phone, mapLink, image, link
}) {
    const number = `tel:${phone}`
    return (
        <a target='blank' href={link} className='w-full sm:w-[300px] xl:w-[380px] h-[300px] flex flex-col items-start'>
            <img className=' aspect-video w-full object-contain rounded-2xl shadow-2xl p-2' src={image} alt="logo" />
            <div className='flex flex-col items-start px-3 mt-3  w-full'>
                <h1 className='text-xl font-semibold text-primary_color'>{name}</h1>
                <h2 className='text-sm leading-6'>{location}</h2>
                <div className='flex mt-3 gap-x-4 '>
                    <a target='blank' href={mapLink}
                        className='flex items-center text-primary_color gap-x-1' >
                        <FaLocationArrow />
                        <p>View in map</p>

                    </a>
                    <a href={number} className='flex items-center gap-x-1'>
                        <IoCall className='text-primary_color'/>
                        <p className='text-sm font-medium'>{phone}</p>
                    </a >
                </div>
            </div>
        </a>
    )
}

export default HospitalCard
