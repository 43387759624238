import React, { useState } from "react";
import ModalData from "../Modal";

const TestCard = ({ name, price, list }) => {
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = (data) => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div
      data-aos="fade-up"
      className="w-full sm:w-[350px] md:w-[400px] h-fit p-5 flex flex-col gap-3
        border-b-4 border-primary_color rounded-lg shadow-lg mb-10"
    >
      <div className="w-full flex flex-col gap-2">
        <p className="text-primary_color font-bold md:text-xl text-start uppercase">
          {name}
        </p>
      </div>
      <ol className="pl-6">
        {list?.map((item, i) => (
          <li key={i} className="text-left list-decimal">
            <p>{item}</p>
          </li>
        ))}
      </ol>
      <div className="w-full flex flex-col gap-3 py-5">
        <span className="text-left text-xl font-semibold text-primary_color">
          <span className="">Price :</span>{" "}
          <span className="font">{price} /-</span>
        </span>
        <button
          onClick={handleOpen}
          className="text-sm bg-primary_color text-white 
        p-2 rounded w-full md:w-1/2"
        >
          Book Health Check-up
        </button>
      </div>

      {open && (
        <ModalData
          open={open}
          handleClose={handleClose}
          setOpen={setOpen}
          name={data?.name}
          department={data?.department}
        />
      )}
    </div>
  );
};

export default TestCard;
