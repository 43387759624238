import React from 'react'
import { Link } from 'react-router-dom'

function DoctorCard() {
    return (
        <div className='flex flex-col items-start'>
            <Link to={"/doctor-profile"}>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxes7NR06YGIhJVT9sFvLIM3Bwno3uqgvahg&s" alt=""
                    className='h-[170px] w-full object-top object-cover'
                />
            </Link>
            <h1 className='pt-2'>Dr.Cherian M Thomas</h1>
            <p className='text-sm text-gray-500'>Chief Orthopaedic Surgeon
            </p>
        </div>
    )
}

export default DoctorCard
