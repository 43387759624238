import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";

const GalCarousel = ({ setModal, imgIndex, imgArray }) => {
  const [currIndex, setCurrIndex] = useState(imgIndex);

  const moveLeft = () => {
    if (currIndex > 0) {
      setCurrIndex(currIndex - 1);
    } else {
      setCurrIndex(imgArray?.length - 1);
    }
  };

  const moveRight = () => {
    if (currIndex < imgArray?.length - 1) {
      setCurrIndex(currIndex + 1);
    } else {
      setCurrIndex(0);
    }
  };

  return (
    <div
      className="w-full h-screen overflow-auto fixed top-0 left-0 bg-black/50 z-50
    flex items-center justify-center select-none"
    >
      <div
        className="w-full h-screen z-50 cursor-pointer absolute top-0 left-0 bg-opacity-0
            flex flex-col justify-end pb-12 xl:pb-0 xl:justify-center "
      >
        <span
          className="z-50 cursor-pointer absolute top-4 right-4 "
          onClick={() => setModal(false)}
        >
          <IoIosCloseCircleOutline size={48} color="white" />
        </span>

        <div className="w-full flex justify-center gap-8 xl:gap-0 xl:justify-between px-10 ">
          <span className="cursor-pointer" onClick={() => moveLeft()}>
            <BsArrowLeftCircle size={48} color="white" />
          </span>

          <span className="cursor-pointer" onClick={() => moveRight()}>
            <BsArrowRightCircle size={48} color="white" />
          </span>
        </div>
      </div>

      <img
        src={imgArray[currIndex]}
        className="w-[90vw] xl:w-fit xl:max-w-[60%] max-h-[70vh] xl:max-h-fit xl:h-screen object-cover"
        alt="carousel"
      />
    </div>
  );
};

export default GalCarousel;
