import React from 'react'

function TieUpCard({name}) {
    return (
        <div className='py-1 px-5 ml-3 w-fit text-base max-md:text-sm rounded-full shadow-[-6px_6px_34px_0px_rgba(0,0,0,0.2)] font-medium'>
            <p>{name}</p>
        </div>
    )
}

export default TieUpCard
